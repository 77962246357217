import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { combineLatest } from 'rxjs';
import { debounceTime, filter, shareReplay, tap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';
import { AreasService } from 'src/app/services/areas.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { PlusService } from 'src/app/services/plus.service';
import { SitesService } from 'src/app/services/sites.service';

@Component({
  standalone: true,
  selector: 'app-stripe-cancel-page',
  templateUrl: './cancel.page.html',
  styleUrls: ['./cancel.page.scss'],
  imports: [
    AppCommonModule,
    IonicModule,
    CommonModule,
    MainPageComponent,
  ],
})
export class StripeCancelPage {
  private areasService = inject(AreasService);
  private plusService = inject(PlusService);
  private route = inject(ActivatedRoute);
  private navigationService = inject(NavigationService);
  private sitesService = inject(SitesService);

  params$ = combineLatest([this.route.queryParams, this.sitesService.getActiveList()]).pipe(
    filter(([_, sites]) => sites?.length > 0),
    debounceTime(1000),
    tap(async ([params, sites]) => params['parent'] !== 'null' ? this.areasService.openRoomTicket(params['parent'], params['ticket']) : this.plusService.onAdHoc(sites)),
    tap(async () => this.navigationService.navigateToHome()),
    shareReplay(1),
  );

  home() {
    this.navigationService.navigateToHome();
  }
}
