
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { fabric } from 'fabric';
import { AppCommonModule } from 'src/app/common.module';
import { deleteIcon } from './delete.button';


@Component({
  standalone: true,
  selector: 'app-canvas',
  // eslint-disable-next-line @angular-eslint/component-max-inline-declarations
  template: `
  @if (!setLocation) {
   <div>
      <ion-button color="light"
        shape="round"
        (click)="add()">
        <ion-icon slot="start" name="add-circle-outline"></ion-icon>
        {{ 'site.addRect' | translate }}
      </ion-button>
   </div>
  }
   <canvas id="c"></canvas>
   @if (!setLocation) {
   <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        (click)="savePath()">
        {{ 'site.savePath' | translate }}
      </ion-button>
      <ion-button color="secondary"
        shape="round"
        (click)="onCancel()">
        {{ 'general.cancel' | translate }}
      </ion-button>
    </div>
   } @else {
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        (click)="savePath()">
        {{ 'general.save' | translate }}
      </ion-button>
   </div>
   }
    `,
  imports: [
    AppCommonModule,
    IonicModule,
  ],
})
export class AppCanvasComponent implements AfterViewInit {

  private fcanvas: fabric.Canvas;

  @Input()
  width = 1200;

  @Input()
  height = 1000;

  @Input()
  imgElement: HTMLImageElement;

  @Input()
  setLocation = false;

  @Output()
  save = new EventEmitter<fabric.Canvas>();

  @Output()
  cancelled = new EventEmitter<void>();

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  private deleteObject(_eventData: MouseEvent, transform: any) {
    const target = transform.target;
    const canvas = target.canvas;
    canvas.remove(target);
    canvas.requestRenderAll();
  }

  private renderIcon(ctx: CanvasRenderingContext2D, left: number, top: number, _styleOverride: unknown, fabricObject: fabric.Object) {
    const img = document.createElement('img');
    img.src = deleteIcon;
    const size = 24;
    ctx.save();
    ctx.translate(left, top);
    ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
    ctx.drawImage(img, -size / 2, -size / 2, size, size);
    ctx.restore();
  }

  ngAfterViewInit() {
    this.fcanvas = new fabric.Canvas('c');
    this.add();
    if (this.imgElement) {
      const img = new fabric.Image(this.imgElement);
      // Get canvas dimensions
      const widthAspectRatio = this.width / img.width;
      const heightAspectRatio = this.height / img.height;
      const finalAspectRatio = Math.min(widthAspectRatio, heightAspectRatio);
      const height = img.height * finalAspectRatio;
      const width = img.width * finalAspectRatio;
      // Set dimension and image
      this.fcanvas.setDimensions({ width, height });
      this.fcanvas.setBackgroundImage(img, this.fcanvas.renderAll.bind(this.fcanvas), {
        scaleX: this.fcanvas.width / img.width,
        scaleY: this.fcanvas.height / img.height,
      },
      );
    }
  }

  add() {
    const rect = new fabric.Rect({
      top: 100,
      left: 100,
      width: this.setLocation ? 10 : 60,
      height: this.setLocation ? 10 : 70,
      stroke: this.setLocation ? '#FF6C74' : '#036291',
      strokeWidth: this.setLocation ? 10 : 8,
      fill: 'transparent',
      strokeUniform: true,
    });
    this.fcanvas.add(rect);
    this.fcanvas.setActiveObject(rect);

    if (!this.setLocation) {
      fabric.Object.prototype.controls.deleteControl = new fabric.Control({
        x: 0.5,
        y: -0.5,
        offsetY: 16,
        cursorStyle: 'pointer',
        mouseUpHandler: this.deleteObject.bind(this),
        render: this.renderIcon.bind(this),
        cornerSize: 24,
      });
    }
  }

  onCancel() {
    this.cancelled.emit();
  }

  savePath() {
    this.fcanvas.discardActiveObject().renderAll();
    this.fcanvas.getElement().toBlob(
      (data: Blob) => {
        this.save.emit(data);
      },
      'image/png',
      0.8,
    );
  }
}
