<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'news.new' | translate }}
  </div>
  @if ({
  roles: roles$ | async
  }; as vm) {
    <form class="ion-padding-bottom"
      [formGroup]="formGroup"
      (ngSubmit)="save()">
      <h3>{{ 'news.title' | translate }}</h3>
      <ion-item lines="none">
        <ion-input autocapitalize="on"
          formControlName="title"></ion-input>
      </ion-item>
      <h3>{{ 'news.text' | translate }}</h3>
      <ion-item lines="none">
        <ion-textarea autocapitalize="on"
          formControlName="text"
          rows="10"
          autoGrow="true"></ion-textarea>
      </ion-item>
      <h3>{{ 'news.role' | translate }}</h3>
      <ion-item lines="none">
        <ion-select aria-label=""
          label-placement="stacked"
          class="full-width"
          [placeholder]="'general.select' | translate"
          interface="popover"
          formControlName="role">
          @for (role of vm.roles; track role) {
            <ion-select-option [value]="role">
              {{ 'users.roles.' + role | translate }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
      <ion-item lines="none">
        <ion-toggle aria-label=""
          formControlName="sendEmail">
          {{ 'news.sendEmail' | translate }}
        </ion-toggle>
      </ion-item>
      <div class="ion-margin-top">
        <ion-button color="light"
          shape="round"
          type="submit"
          [disabled]="!formGroup.valid">{{ 'general.save' | translate }}</ion-button>
      </div>
    </form>
  }

</app-main-dialog>