import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { HideHeaderDirective } from './directives/hide-header/hide-header.directive';

@NgModule({
  imports: [ReactiveFormsModule, TranslateModule, HideHeaderDirective],
  exports: [ReactiveFormsModule, TranslateModule, HideHeaderDirective],
})
export class AppCommonModule { }
