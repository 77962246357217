import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { toObservable } from '@angular/core/rxjs-interop';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { fieldSorter } from '@scandium-oy/ngx-scandium';
import { startOfToday } from 'date-fns';
import { Observable, combineLatest } from 'rxjs';
import { map, shareReplay, switchMap, take } from 'rxjs/operators';
import { AppCommonModule } from '../common.module';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { MonthSwitchComponent } from '../components/month-switch/month-switch.component';
import { NoResultsComponent } from '../components/no-results/no-results.component';
import { ContractorsService } from '../services/contractors.service';
import { LicensesService } from '../services/licenses.service';
import { UsersService } from '../services/users.service';
import { TextDialogComponent } from '../text-dialog/text-dialog.dialog';

interface UsageContractor {
  guid: string;
  name: string;
  count: number;
  price: number;
  total: number;
  users: string[];
  license: string;
}

@Component({
  standalone: true,
  selector: 'app-invoicing-page',
  templateUrl: './invoicing.page.html',
  styleUrls: ['./invoicing.page.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainPageComponent,
    MonthSwitchComponent,
    NoResultsComponent,
  ],
})
export class InvoicingPage {

  currentDate = signal<Date>(startOfToday());

  private usages$: Observable<UsageContractor[]> = combineLatest([toObservable(this.currentDate), this.contractorsService.getList()]).pipe(
    map(([date, contractors]) => contractors.map((c) => {
      const license$ = this.licensesService.get(c.guid, date.getFullYear(), date.getMonth() + 1);
      return Object.assign({}, c, { license$: license$ });
    })),
    map((items) => {
      const obs = items.map((it) => it.license$);
      return combineLatest(obs);
    }),
    switchMap((licenses) => combineLatest([this.contractorsService.getList(), licenses])),
    map(([contractors, licenses]) => licenses.filter((l) => l != null).map((l) => {
      const contractor = contractors.find((c) => c.guid === l.contractor);
      return {
        guid: l.guid,
        name: contractor?.name,
        count: l.count,
        price: l.price,
        total: l.price * l.count,
        users: l.users,
        license: l.license,
      };
    }),
    ),
    shareReplay(1),
  );

  paid$ = this.usages$.pipe(
    map((items) => items.filter((it) => it.license !== 'free')),
    shareReplay(1),
  );

  free$ = this.usages$.pipe(
    map((items) => items.filter((it) => it.license === 'free')),
    shareReplay(1),
  );

  constructor(
    private contractorsService: ContractorsService,
    private licensesService: LicensesService,
    private modalCtrl: ModalController,
    private translate: TranslateService,
    private usersService: UsersService,
  ) { }

  changeMonth(newMonth: Date) {
    this.currentDate.set(newMonth);
  }

  openUsers(userGuids: string[]) {
    this.usersService.getByGuids(userGuids).pipe(
      take(1),
    ).subscribe((users) => {
      const text = users.sort(fieldSorter(['displayName'])).map((u) => `${u.displayName}<br/>`).join('<br/>');
      this.modalCtrl.create({ component: TextDialogComponent, componentProps: { title: this.translate.instant('nav.users'), text } }).then(async (m) => m.present());
    });
  }
}
