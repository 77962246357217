import { Location as AngularLocation } from '@angular/common';
import { Injectable, computed, signal } from '@angular/core';
import { ActivatedRoute, NavigationExtras, Params, Router } from '@angular/router';
import { INavigationService } from '@scandium-oy/ngx-scandium';
import { environment } from 'src/environments/environment';
import { Classifications } from '../constants/classifications';
import { Roles } from '../utility/role';
import { UsersService } from './users.service';

@Injectable({
  providedIn: 'root',
})
export class NavigationService extends INavigationService {

  private tab = signal<string>(null);

  selectedTab = computed(() => this.tab());

  constructor(
    private route: ActivatedRoute,
    private location: AngularLocation,
    private usersService: UsersService,
    router: Router,
  ) {
    super(router);
  }

  public async navigate(commands: string[], extras?: NavigationExtras) {
    return this.router.navigate(commands, extras);
  }

  public async navigateToHome() {
    const user = this.usersService.currentUserS();
    if ([Roles.manager, Roles.calendar].includes(user?.role)) {
      if (user.worksites?.length === 1) {
        return this.navigateToSite(user.worksites[0]);
      } else {
        return this.navigate(['/worker', 'sites']);
      }
    }
    const route = environment.home;
    return this.router.navigate([`/${route}`]);
  }

  public async navigateToContractor(guid: string) {
    return this.router.navigate(['/contractors', guid]);
  }

  public async navigateToSite(siteGuid: string, queryParams?: Params) {
    return this.router.navigate(['/sites/site', siteGuid], { queryParams });
  }

  public async navigateToSiteFeed(siteGuid: string, project?: string) {
    return this.router.navigate(['/sites/site', siteGuid, 'feed'], { queryParams: { project } });
  }

  public async navigateToSiteProject(siteGuid: string, project: string) {
    return this.router.navigate(['/sites/site', siteGuid, 'project', project]);
  }

  public async navigateToSiteTickets(siteGuid: string, project?: string, period?: string) {
    return this.router.navigate(['/kanban-dashboard', siteGuid], { queryParams: { project, period } });
  }

  public async navigateToLittera(guid: string, classification: Classifications) {
    return this.router.navigate(['/littera', guid], { queryParams: { classification } });
  }

  public async navigateToLastplanner(guid: string) {
    return this.router.navigate(['/lastplanner', guid]);
  }

  public async navigateToLogs(tabs: string) {
    return this.router.navigate(['/logs'], { queryParams: { tabs } });
  }

  public async navigateToOrders() {
    return this.router.navigate(['/materials/process']);
  }

  public async navigateToUser(userGuid: string) {
    return this.router.navigate(['/users/user', userGuid]);
  }

  public async navigateIfSame(url: string, guid: string, extras?: NavigationExtras) {
    const currentUrl = window.location.href;
    if (currentUrl.includes(url)) {
      return this.router.navigate([url, guid], extras);
    }
  }

  public updateQueryParams(queryParams: Params) {
    this.router.navigate(
      [],
      {
        relativeTo: this.route,
        queryParams,
      });
  }

  public async navigateToLogin(redirectUrlParam?: string) {
    const redirectUrl = ['/profile', '/waiting', '/stripe/order'].includes(redirectUrlParam) ? null : redirectUrlParam;
    return this.router.navigate(['/login'], { replaceUrl: true, queryParams: { redirectUrl } });
  }

  public back() {
    return this.location.back();
  }

  setSelectedTab(value: string) {
    this.tab.set(value);
  }

}
