import { registerLocaleData } from '@angular/common';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import localeFiExtra from '@angular/common/locales/extra/fi';
import localeFi from '@angular/common/locales/fi';
import { ErrorHandler, LOCALE_ID, NgModule } from '@angular/core';
import { getApp, initializeApp, provideFirebaseApp } from '@angular/fire/app';
import { getAuth, indexedDBLocalPersistence, initializeAuth, provideAuth } from '@angular/fire/auth';
import { getFirestore, provideFirestore } from '@angular/fire/firestore';
import { getStorage, provideStorage } from '@angular/fire/storage';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { ServiceWorkerModule } from '@angular/service-worker';
import { Capacitor } from '@capacitor/core';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { IonicStorageModule } from '@ionic/storage-angular';
import { TranslateModule } from '@ngx-translate/core';
import { INavigationService, LoadingModule, LogoModule, TokenInterceptor } from '@scandium-oy/ngx-scandium';
import { init } from '@sentry/angular';
/*import * as Sentry from '@sentry/capacitor';*/
import { getFunctions, provideFunctions } from '@angular/fire/functions';
import { NgCircleProgressModule } from 'ng-circle-progress';
import { NgScrollbarModule } from 'ngx-scrollbar';
import { environment } from 'src/environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AdhocComponent } from './components/ad-hoc/adhoc.component';
import { SwipeDirective } from './directives/swipe/swipe.directive';
import { GlobalErrorHandler } from './handlers/global-error.handler';
import { NavigationService } from './services/navigation.service';
import { translateConfig } from './utility/translateConfig';

init({
  dsn: environment.sentry,
  debug: !environment.production,
  ignoreErrors: [
    'ResizeObserver loop completed with undelivered notifications.',
    'ResizeObserver loop limit exceeded',
  ],
});

registerLocaleData(localeFi, 'fi-FI', localeFiExtra);

const circleConfig = {
  radius: 100,
  outerStrokeWidth: 16,
  innerStrokeWidth: 8,
  outerStrokeColor: '#78C000',
  innerStrokeColor: '#C7E596',
  animationDuration: 300,
};

@NgModule({
  declarations: [AppComponent],
  bootstrap: [AppComponent],
  imports: [
    BrowserModule,
    IonicModule.forRoot({
      backButtonText: '',
      backButtonIcon: 'chevron-back-outline',
    }),
    IonicStorageModule.forRoot(),
    AppRoutingModule,
    TranslateModule.forRoot(translateConfig),
    NgCircleProgressModule.forRoot(circleConfig),
    ReactiveFormsModule,
    LoadingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !Capacitor.isNativePlatform() && environment.production,
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
    AdhocComponent,
    NgScrollbarModule,
    LogoModule,
    SwipeDirective,
  ],
  providers: [
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    { provide: LOCALE_ID, useValue: 'fi-FI' },
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: INavigationService, useClass: NavigationService },
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    provideHttpClient(withInterceptorsFromDi()),
    provideFirebaseApp(() => initializeApp(environment.firebase)),
    provideAuth(() => {
      if (Capacitor.isNativePlatform()) {
        return initializeAuth(getApp(), {
          persistence: indexedDBLocalPersistence,
        });
      } else {
        return getAuth();
      }
    }),
    provideFirestore(() => getFirestore()),
    provideStorage(() => getStorage()),
    provideFunctions(() => getFunctions(undefined, environment.functionRegion)),
  ],
})
export class AppModule { }
