import { CommonModule } from '@angular/common';
import { AfterContentInit, Component, computed, inject, input, output, signal } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from '@scandium-oy/ngx-scandium';
import { addMonths } from 'date-fns';
import { take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { ContractorOffer } from 'src/app/models/contractor-offer.model';
import { FileUpload } from 'src/app/models/file.model';
import { TimestampPipe } from 'src/app/pipes/timestamp/timestamp.pipe';
import { ContractorOffersService } from 'src/app/services/contractor-offers.service';
import { PrintService } from 'src/app/services/print.service';
import { UploadService } from 'src/app/services/upload.service';
import { LANGUAGES } from 'src/app/utility/constants';
import { convertTimestamp } from 'src/app/utility/time';
import { css } from './print-offer.css';

@Component({
  standalone: true,
  selector: 'app-print-offer',
  templateUrl: './print-offer.component.html',
  styleUrls: ['./print-offer.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    TimestampPipe,
  ],
})
export class PrintOfferComponent implements AfterContentInit {
  private contractorOffersService = inject(ContractorOffersService);
  private loadingService = inject(LoadingService);
  private printService = inject(PrintService);
  private translate = inject(TranslateService);
  private uploadService = inject(UploadService);

  item = input.required<ContractorOffer>();
  printed = output<string>();
  logo = signal('https://firebasestorage.googleapis.com/v0/b/urakka-app-prod.appspot.com/o/public%2Fimages%2Flogo.png?alt=media&token=de043ebb-5b1d-4363-847d-367eb7e7fd23');

  endTime = computed(() => {
    if (this.item().term === 'indefinetly') {
      return null;
    }
    const months = +this.item().term;
    return addMonths(convertTimestamp(this.item().start), months);
  });

  ngAfterContentInit() {
    this.customPrint();
  }

  customPrint() {
    const initialLang = this.translate.currentLang;
    this.translate.use(this.item().lang ?? LANGUAGES[0]);
    this.loadingService.showLoading();
    setTimeout(() => {
      const name = `${this.item().contractorName}-${new Date().toISOString()}`;
      const collection = document.getElementsByClassName('page');
      const elements = Array.from(collection).map((it) => it as HTMLElement);
      this.printService.printDoc(name, elements, css, { orientation: 'portrait', scale: 2, output: true }).then((blob) => {
        this.loadingService.hideLoading();
        if (blob) {
          const file = new File([blob], `${name}.pdf`);
          const fileupload = new FileUpload(file);
          fileupload.guid = this.item().guid;
          this.uploadService.uploadImage(fileupload).then((url) => {
            this.contractorOffersService.sendOffer(fileupload.guid, url, this.item().customerEmail).pipe(
              take(1),
            ).subscribe();
            this.printed.emit(url);
            this.translate.use(initialLang);
          });
        }
      }, (e) => {
        console.error(e);
        this.loadingService.hideLoading();
        this.printed.emit(null);
        this.translate.use(initialLang);
      });
    }, 50);
  }
}
