import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { RouterModule } from '@angular/router';
import { IonicModule } from '@ionic/angular';
import { filter, shareReplay } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { UsersService } from 'src/app/services/users.service';

@Component({
  standalone: true,
  selector: 'app-login-icon',
  templateUrl: './login-icon.component.html',
  styleUrls: ['./login-icon.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    AppCommonModule,
    RouterModule,
  ],
})
export class LoginIconComponent {

  user$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    shareReplay(1),
  );

  constructor(
    private usersService: UsersService,
  ) { }
}
