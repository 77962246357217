import { CommonModule } from '@angular/common';
import { Component, OnInit, computed, inject, signal } from '@angular/core';
import { Params } from '@angular/router';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { AppCommonModule } from '../common.module';
import { MainDialogComponent } from '../components/main-dialog/main-dialog.component';
import { adminMenu } from '../menu/admin';
import { managerMenu } from '../menu/manager';
import { MenuItem } from '../menu/menu.item.model';
import { partnerMenu } from '../menu/partner';
import { storageMenu } from '../menu/storage';
import { superAdminMenu } from '../menu/super-admin';
import { supervisorMenu } from '../menu/supervisor';
import { workerMenu } from '../menu/worker';
import { ContractorsService } from '../services/contractors.service';
import { NavigationService } from '../services/navigation.service';
import { UsersService } from '../services/users.service';
import { Roles } from '../utility/role';

@Component({
  standalone: true,
  selector: 'app-nav-dialog',
  templateUrl: './nav.dialog.html',
  styleUrls: ['./nav.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class NavDialogComponent implements OnInit {
  private contractorsService = inject(ContractorsService);
  private _modal = inject(ModalController);
  private navigationService = inject(NavigationService);
  private usersService = inject(UsersService);

  readonly appMenu = {
    admin: adminMenu,
    manager: managerMenu,
    superAdmin: superAdminMenu,
    worker: workerMenu,
    storage: storageMenu,
    supervisor: supervisorMenu,
    partner: partnerMenu,
  };

  contractor = computed(() => this.contractorsService.contractorS());
  role = this.usersService.role;

  version = signal<string>(null);

  selectedItem = signal<MenuItem>(null);

  ngOnInit(): void {
    if (Capacitor.isNativePlatform()) {
      App.getInfo().then((info) => this.version.set(info.version));
    }
  }

  navigate(route: string[], queryParams?: Params) {
    this.navigationService.navigate(route, { queryParams }).then(() => this.dismiss());
  }

  onItem(item: MenuItem) {
    const user = this.usersService.currentUserS();
    if ([Roles.superAdmin, Roles.partner, Roles.manager].includes(user.role)) {
      if (item.route) {
        this.navigate([item.route], item.queryParams);
      } else if (item.children?.length > 0) {
        this.selectedItem.set(item);
      }
    } else {
      if (item.route) {
        this.navigate([item.route], item.queryParams);
      } else if (item.children?.length > 0) {
        this.selectedItem.set(item);
      }
    }
  }

  dismiss(back = false) {
    if (back && this.selectedItem() != null) {
      this.selectedItem.set(null);
      return;
    }
    this._modal.dismiss();
  }
}
