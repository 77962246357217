import { Injectable, signal } from '@angular/core';
import {
  Firestore, FirestoreDataConverter, QueryConstraint,
  addDoc, collection, collectionData, deleteDoc,
  disableNetwork, doc, docData, enableNetwork, getDoc, getDocs, query, setDoc,
  setLogLevel,
  updateDoc,
} from '@angular/fire/firestore';
import { Storage as FireStorage, getDownloadURL, ref, uploadBytes } from '@angular/fire/storage';
import { Observable, from, of } from 'rxjs';
import { catchError, map, tap } from 'rxjs/operators';
import { FileUpload } from '../models/file.model';
import { FirestoreItem } from '../models/firestore-item.model';
import { fieldSorter } from '../utility/sort';

@Injectable({
  providedIn: 'root',
})
export class FirestoreService {
  private isOfflineS = signal(false);

  constructor(
    private firestore: Firestore,
    private storage: FireStorage,
  ) {
    setLogLevel('error');
  }

  private printQueryConstraints(list: QueryConstraint[]) {
    const ret: string[] = [];

    list.map((q) => {
      if (q.type === 'where') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const qq = q as any;
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions
        ret.push(`${qq._field.segments}`);
      }
    });

    return ret.join(',');
  }

  private checkQueryConstraints(collectionKey: string, list: QueryConstraint[]) {
    list.map((q) => {
      if (q.type === 'where') {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const qq = q as any;
        if (qq._value === undefined) {
          console.warn(collectionKey, 'Where value is undefined', qq._field.segments, qq._op);
        }
      }
    });
  }

  async saveAs<T>(collectionKey: string, guid: string, item: T, converter: FirestoreDataConverter<T, unknown> = null) {
    const docRef = doc(this.firestore, collectionKey, guid).withConverter(converter);
    return setDoc(docRef, item);
  }

  async save<T>(collectionKey: string, item: T, converter: FirestoreDataConverter<T, unknown> = null) {
    const collectionRef = collection(this.firestore, collectionKey).withConverter(converter);
    return addDoc(collectionRef, item);
  }

  async update<T extends FirestoreItem>(collectionKey: string, item: T, converter: FirestoreDataConverter<T, unknown> = null) {
    const docRef = doc(this.firestore, collectionKey, item.guid).withConverter(converter);
    return setDoc(docRef, item);
  }

  async updateOnly(collectionKey: string, guid: string, value: object, converter: FirestoreDataConverter<unknown, unknown> = null) {
    const docRef = doc(this.firestore, collectionKey, guid).withConverter(converter);
    return updateDoc(docRef, value);
  }

  async softDelete<T extends FirestoreItem>(collectionKey: string, item: T, converter: FirestoreDataConverter<T, unknown> = null) {
    const docRef = doc(this.firestore, collectionKey, item.guid).withConverter(converter);
    return updateDoc(docRef, { deleted: true });
  }

  async delete<T extends FirestoreItem>(collectionKey: string, item: T, converter: FirestoreDataConverter<T, unknown> = null) {
    const docRef = doc(this.firestore, collectionKey, item.guid).withConverter(converter);
    return deleteDoc(docRef);
  }

  async done<T extends FirestoreItem>(collectionKey: string, item: T, converter: FirestoreDataConverter<T, unknown> = null) {
    const docRef = doc(this.firestore, collectionKey, item.guid).withConverter(converter);
    return updateDoc(docRef, { done: true });
  }

  get<T extends FirestoreItem>(
    collectionKey: string,
    guid: string,
    converter: FirestoreDataConverter<T, unknown> = null,
  ): Observable<T> {
    const docRef = doc(this.firestore, collectionKey, guid).withConverter(converter);
    return docData(docRef, { idField: 'guid' }).pipe(
      catchError((err) => of(null).pipe(tap((_) => console.info(`get ${collectionKey} '${guid}' fetch error`, err)))),
      map((item) => {
        if (item) {
          item.guid = guid;
          return item;
        } else {
          return null;
        }
      }),
    );
  }

  getOnce<T extends FirestoreItem>(
    collectionKey: string,
    guid: string,
    converter: FirestoreDataConverter<T, unknown> = null,
  ): Observable<T> {
    const docRef = doc(this.firestore, collectionKey, guid).withConverter(converter);
    return from(getDoc(docRef)).pipe(
      catchError((err) => of(null).pipe(tap((_) => console.info(`getOnce ${collectionKey} '${guid}' fetch error`, err)))),
      map((ret) => {
        const item = ret?.data();
        if (item) {
          item.guid = guid;
          return item;
        } else {
          return null;
        }
      }),
    );
  }

  getList<T extends FirestoreItem>(
    collectionKey: string,
    orderBy?: { value: string; sort: string },
    queryConstraints: QueryConstraint[] = [],
    converter: FirestoreDataConverter<T, unknown> = null,
  ): Observable<T[]> {
    this.checkQueryConstraints(collectionKey, queryConstraints);
    const collectionRef = collection(this.firestore, collectionKey).withConverter(converter);
    const q = query(collectionRef, ...queryConstraints);
    return collectionData(q, { idField: 'guid' }).pipe(
      catchError((err) => of(null).pipe(tap((_) => console.info(`getList ${collectionKey} '${this.printQueryConstraints(queryConstraints)}' fetch error`, err)))),
      map((items) => {
        if (orderBy) {
          items = items?.sort(fieldSorter([orderBy.value]));
          if (orderBy.sort === 'desc') {
            items = items?.reverse();
          }
        }
        return items?.map((item) => item as T) ?? [];
      }),
    );
  }

  getListOnce<T extends FirestoreItem>(
    collectionKey: string,
    orderBy?: { value: string; sort: string },
    queryConstraints: QueryConstraint[] = [],
    converter: FirestoreDataConverter<T, unknown> = null,
  ): Observable<T[]> {
    this.checkQueryConstraints(collectionKey, queryConstraints);
    const collectionRef = collection(this.firestore, collectionKey).withConverter(converter);
    const q = query(collectionRef, ...queryConstraints);
    return from(getDocs(q).then((ret) => {
      let items = ret.docs.map((d) => {
        const item = d.data();
        item.guid = d.id;
        return item;
      });
      if (orderBy) {
        items = items?.sort(fieldSorter([orderBy.value]));
        if (orderBy.sort === 'desc') {
          items = items?.reverse();
        }
      }
      return items?.map((item) => item) ?? [];
    }),
    ).pipe(
      catchError((err) => of(null).pipe(tap((_) => console.info(`getListOnce ${collectionKey} '${this.printQueryConstraints(queryConstraints)}' fetch error`, err)))),
    );
  }

  async toggleNetwork(offline: boolean) {
    const currIsOffline = this.isOfflineS();
    if (!currIsOffline && offline) {
      console.info('Setting network offline', currIsOffline);
      this.isOfflineS.set(true);
      return disableNetwork(this.firestore);
    } else if (currIsOffline && !offline) {
      console.info('Setting network online', currIsOffline);
      this.isOfflineS.set(false);
      return enableNetwork(this.firestore);
    }
  }

  isOnline() {
    return !this.isOfflineS();
  }

  async pushFileToStorage(fileUpload: FileUpload, path: string): Promise<string> {
    const filePath = `${path}/${fileUpload.file.name}`;
    const storageRef = ref(this.storage, filePath);
    const result = await uploadBytes(storageRef, fileUpload.file);
    return getDownloadURL(result.ref);
  }

  async pushFileToStorageRef(fileUpload: FileUpload, path: string) {
    const filePath = `${path}/${fileUpload.file.name}`;
    const storageRef = ref(this.storage, filePath);
    const result = await uploadBytes(storageRef, fileUpload.file);
    return result.ref;
  }
}
