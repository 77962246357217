import { computed, inject, Injectable } from '@angular/core';
import { filter, map } from 'rxjs/operators';
import { ContractorsService } from './contractors.service';
import { UsersService } from './users.service';

export const appModules = {
  essentials: 'essentials',
  location: 'location',
  reporting: 'reporting',
  users: 'users',
  products: 'products',
  workMachines: 'workMachines',
  timesheets: 'timesheets',
  invoices: 'invoices',
};

export const modulePrices = [
  9.90,
  9.90,
  9.90,
  9.90,
  9.90,
  9.90,
  9.90,
  9.90,
];

export const packages = {
  boost: {
    name: 'boost',
    modules: [
      appModules.essentials, appModules.location, appModules.reporting, appModules.workMachines, appModules.products, appModules.timesheets, appModules.invoices, appModules.users,
    ],
  },
};

export const packageList = ['free', packages.boost.name];

@Injectable({
  providedIn: 'root',
})
export class LicenseService {
  private contractorsService = inject(ContractorsService);
  private usersService = inject(UsersService);

  isFreeLicenseS = computed(() => {
    const contractor = this.contractorsService.contractorS();
    return contractor?.license === 'free';
  });

  missingBillingAccount = computed(() => this.contractorsService.contractorS()?.billingAccount === null);
  isBillingAccount = computed(() => this.contractorsService.contractorS()?.billingAccount?.guid === this.usersService.currentUserS()?.guid);
  billingAccountName = computed(() => this.contractorsService.contractorS()?.billingAccount?.name);

  isFreeLicence() {
    return this.contractorsService.getCurrentContractor().pipe(
      filter((contractor) => contractor != null),
      map((contractor) => {
        return contractor.license === 'free';
      }),
    );
  }
}
