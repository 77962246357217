import { Injectable } from '@angular/core';
import { Dialog } from '@capacitor/dialog';
import { TranslateService } from '@ngx-translate/core';
import { SentryErrorHandler } from '@sentry/angular';

@Injectable()
export class GlobalErrorHandler extends SentryErrorHandler {

  constructor(
    private translate: TranslateService,
  ) {
    super();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleError(err: any): void {
    const chunkFailedMessage = /Loading chunk [\d]+ failed/;
    console.error(err);
    if (err?.message != null && chunkFailedMessage.test(err.message)) {
      const title = this.translate.instant('update.title');
      const message = this.translate.instant('update.message');

      Dialog.confirm({ title, message }).then((dialog) => {
        if (dialog.value) {
          window.location.reload();
        }
      });
    }
    super.handleError(err);
  }
}
