import { DOCUMENT } from '@angular/common';
import { Component, DestroyRef, EnvironmentInjector, OnInit, computed, inject } from '@angular/core';
import { takeUntilDestroyed, toObservable } from '@angular/core/rxjs-interop';
import { serverTimestamp } from '@angular/fire/firestore';
import { NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router } from '@angular/router';
import { NativeMarket } from '@capacitor-community/native-market';
import { App, AppInfo } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { Dialog } from '@capacitor/dialog';
import { SplashScreen } from '@capacitor/splash-screen';
import { StatusBar, Style } from '@capacitor/status-bar';
import { ModalController, Platform } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { AppUpdateService } from '@scandium-oy/ngx-scandium';
import { zip } from 'rxjs';
import { debounceTime, filter, map, shareReplay, switchMap, take } from 'rxjs/operators';
import { register } from 'swiper/element/bundle';
import { User } from './models/user.model';
import { NavDialogComponent } from './nav/nav.dialog';
import { FirstLoginDialogComponent } from './on-boarding/first-login/first-login.dialog';
import { InitializeService } from './services/initialize.service';
import { NavigationService } from './services/navigation.service';
import { StripeService } from './services/stripe.service';
import { THEMES, ThemeService } from './services/theme.service';
import { UsersService } from './services/users.service';
import { VersionService } from './services/version.service';
import { LANGUAGES } from './utility/constants';
import { Roles } from './utility/role';
import { getDuration, setStartTime } from './utility/time';

register();

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  private documentRef = inject(DOCUMENT);
  private themeService = inject(ThemeService);

  private destroyRef = inject(DestroyRef);
  private lastBack: number;
  private termsOpen = false;

  currentTheme = computed(() => {
    const theme = this.themeService.getTheme();
    const themeColor = THEMES.find((it) => it.value === theme()).color;

    this.documentRef.querySelector('meta[name=\'theme-color\']').setAttribute('content', themeColor);
    return theme();
  });

  role = this.usersService.role;

  loading$ = this.router.events.pipe(
    filter(
      (e) =>
        e instanceof NavigationStart ||
        e instanceof NavigationEnd ||
        e instanceof NavigationCancel ||
        e instanceof NavigationError,
    ),
    map((e) => e instanceof NavigationStart),
  );

  loadingSites = computed(() => this.initializeService.loadingSites());
  isLogged = computed(() => this.usersService.currentUserS() != null);

  licenseExpired$ = this.usersService.isLicenseExpired$.pipe(
    shareReplay(1),
  );

  constructor(
    private appUpdateService: AppUpdateService,
    private initializeService: InitializeService,
    private injectorContext: EnvironmentInjector,
    private modalCtrl: ModalController,
    private navigationService: NavigationService,
    private platform: Platform,
    private router: Router,
    private stripeService: StripeService,
    private translate: TranslateService,
    private usersService: UsersService,
    private versionService: VersionService,
  ) {
    this.platform.backButton.subscribeWithPriority(-1, () => {
      if (Date.now() - this.lastBack < 500) {
        App.exitApp();
      } else {
        this.lastBack = Date.now();
        this.navigationService.back();
      }
    });
  }

  onSwipeBack() {
    if (this.platform.is('ios') && this.platform.is('capacitor')) {
      this.navigationService.back();
    }
  }

  ngOnInit(): void {
    this.initializeApp();
  }

  private openTerms(user: User) {
    if (!this.termsOpen) {
      console.info('Showing terms');
      this.termsOpen = true;
      this.modalCtrl.create({
        component: FirstLoginDialogComponent,
        backdropDismiss: false,
      },
      ).then((m) => {
        m.present();

        m.onDidDismiss().then((_) => {
          user.acceptedTerms = serverTimestamp();
          this.usersService.update(user);
        });
      });
    }
  }

  private initializeApp() {
    console.info('Initialize App');
    this.setupLang();
    this.stripeService.initialize();
    SplashScreen.hide().catch((err) => {
      console.warn(err);
    });
    StatusBar.setStyle({
      style: Style.Light,
    }).catch((err) => {
      console.warn(err);
    });
    if (Capacitor.isNativePlatform()) {
      App.getInfo().then((info) => this.checkLatestVersion(info));
    }
    // Subscribe to streams
    // App updates
    this.appUpdateService.getVersionUpdates().pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe();

    // User
    this.initializeService.loadUser().pipe(
      debounceTime(50),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe((loggedIn) => {
      if (!loggedIn) {
        const url = this.router.url.split('?')[0];
        if (!['/sign-up', '/privacy'].includes(url) && !url.startsWith('/instructions/public')) {
          this.navigationService.navigateToLogin(url);
        }
      } else {
        const user = this.usersService.currentUserS();
        if ([Roles.manager, Roles.calendar].includes(user.role) && !user?.acceptedTerms) {
          this.openTerms(user);
        }
      }
    });

    setStartTime();
    zip([
      this.initializeService.loadUsers(),
      this.initializeService.loadContractor(),
      this.initializeService.loadClientsData(),
      this.initializeService.loadSitesData(),
    ]).pipe(
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => console.info(getDuration(), 'Data loaded'));

    toObservable(this.loadingSites, { injector: this.injectorContext }).pipe(
      filter((loading) => !loading),
      switchMap(() => zip([
        this.initializeService.loadGlobalOptionsData(),
        this.initializeService.loadMaterialsData(),
      ])),
      takeUntilDestroyed(this.destroyRef),
    ).subscribe(() => console.info(getDuration(), 'Options and materials loaded'));
  }

  private setupLang() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang(LANGUAGES[0]);
    this.translate.addLangs(LANGUAGES);
    this.translate.use(LANGUAGES[0]);
  }

  private checkLatestVersion(info: AppInfo) {
    const build = +info.build;
    const guid = Capacitor.getPlatform();
    this.versionService.get(guid).pipe(
      take(1),
    ).subscribe((version) => {
      const latestBuild = +(version?.build ?? 0);
      if (latestBuild > build) {
        Dialog.confirm({
          title: this.translate.instant('version.title'),
          message: this.translate.instant('version.message'),
          okButtonTitle: this.translate.instant('version.ok'),
          cancelButtonTitle: this.translate.instant('version.cancel'),
        }).then((result) => {
          if (result.value) {
            // Open store
            NativeMarket.openStoreListing({
              appId: 'fi.scandium.urakka',
            });
          } else {
            App.exitApp();
          }
        });
      }
    });
  }

  onHome() {
    setTimeout(() => {
      this.navigationService.navigateToHome();
    }, 50);
  }

  onMenu() {
    this.modalCtrl.create({ component: NavDialogComponent, cssClass: ['modal-fullscreen'] }).then(async (m) => m.present());
  }
}
