import { CommonModule } from '@angular/common';
import { Component, computed, inject, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { IonicModule, IonInput, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { CameraButtonModule, fieldSorter } from '@scandium-oy/ngx-scandium';
import { map, shareReplay, take } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { ImagesComponent } from 'src/app/components/images/images.component';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { TakeVideoComponent } from 'src/app/components/take-video/take-video.component';
import { InstructionCategory } from 'src/app/models/instruction-category.model';
import { Instruction } from 'src/app/models/instruction.model';
import { SelectDialogComponent } from 'src/app/select-dialog/select.dialog';
import { ChecklistService } from 'src/app/services/checklist.service';
import { InstructionsLinkService } from 'src/app/services/instructions-link.service';
import { InstructionsService } from 'src/app/services/instructions.service';
import { UsersService } from 'src/app/services/users.service';
import { CleaningList } from 'src/app/utility/cleaningList';
import { Roles } from 'src/app/utility/role';
import { SelectCategoryDialogComponent } from '../category/select-category/select-category.dialog';

@Component({
  standalone: true,
  selector: 'app-new-item-dialog',
  templateUrl: './new-item.dialog.html',
  styleUrls: ['./new-item.dialog.scss'],
  imports: [
    IonicModule,
    CommonModule,
    AppCommonModule,
    ImagesComponent,
    CameraButtonModule,
    TakeVideoComponent,
    MainDialogComponent,
  ],
})
export class NewItemDialogComponent implements OnInit {
  private translate = inject(TranslateService);
  private usersService = inject(UsersService);

  categories$ = this.instructionsService.getCategories().pipe(
    map((items) => items?.sort(fieldSorter(['name']))),
    shareReplay(1),
  );

  formGroup: FormGroup;

  kanbanList$ = this.checklistService.get(CleaningList.kanban).pipe(
    map((list) => list == null ? [] : list.fields),
    map((items) => items.sort()),
    shareReplay(1),
  );

  roleS = computed(() => this.usersService.currentUserS()?.role);

  constructor(
    private checklistService: ChecklistService,
    private instructionsService: InstructionsService,
    private instructionsLinkService: InstructionsLinkService,
    private _modal: ModalController,
    private modalCtrl: ModalController,
    formBuilder: FormBuilder,
    navParams: NavParams,
  ) {
    const item = navParams.get<Instruction>('item');

    this.formGroup = formBuilder.group({
      guid: [item?.guid ?? null],
      category: [item?.category ?? null, Validators.required],
      checklist: [item?.checklist ?? []],
      execute: formBuilder.group({
        fi: [item?.execute.fi ?? ''],
      }),
      model: [item?.model ?? ''],
      name: formBuilder.group({
        fi: [item?.name.fi ?? '', Validators.required],
      }),
      quality: [item?.quality ?? []],
      safety: formBuilder.group({
        fi: [item?.safety.fi ?? ''],
      }),
      tickets: [[]],
      contractor: [item?.contractor ?? null],
      roles: [item?.roles ?? [Roles.admin, Roles.worker, Roles.storage]],
      public: [false],
    });
  }

  private saveTicketsLink(instruction: string, tickets: string[]) {
    this.instructionsLinkService.get({ instruction }).pipe(
      take(1),
    ).subscribe((link) => {
      if (link) {
        link.tickets = tickets;
        this.instructionsLinkService.update(link);
      } else {
        link = {
          instruction,
          tickets,
        };
        this.instructionsLinkService.save(link);
      }
      this.dismiss();
    });
  }

  ngOnInit(): void {
    const guid: string = this.formGroup.get('guid').value;
    if (guid) {
      this.instructionsLinkService.get({ instruction: guid }).pipe(
        take(1),
      ).subscribe((link) => this.formGroup.get('tickets').setValue(link?.tickets ?? []));
    }
  }

  selectCategory(items: InstructionCategory[]) {
    const clearButton = this.formGroup.get('category').value != null;
    this.modalCtrl.create({ component: SelectCategoryDialogComponent, componentProps: { items, clearButton } }).then((m) => {
      m.present();

      m.onDidDismiss<InstructionCategory>().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('category').setValue(null);
          } else {
            const selected = { name: data.data.name, guid: data.data.guid };
            this.formGroup.get('category').setValue(selected);
          }
        }
      });
    });
  }

  selectTickets(items: string[]) {
    const clearButton = this.formGroup.get('tickets').value.length > 0;
    this.modalCtrl.create({ component: SelectDialogComponent, componentProps: { items: items.map((it) => ({ name: it })), clearButton, multiple: true } }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('tickets').setValue([]);
          } else {
            this.formGroup.get('tickets').setValue(data.data.map((it) => it.name));
          }
        }
      });
    });
  }

  selectRoles() {
    const selected = this.formGroup.get('roles').value;
    const clearButton = selected.length > 0;
    const roles = [Roles.admin, Roles.worker, Roles.storage];
    this.modalCtrl.create({
      component: SelectDialogComponent,
      componentProps: { items: roles.map((it) => ({ name: this.translate.instant('users.roles.' + it), guid: it, selected: selected.includes(it) })), clearButton, multiple: true },
    }).then((m) => {
      m.present();

      m.onDidDismiss().then((data) => {
        if (data.data) {
          if (data.data.clear) {
            this.formGroup.get('roles').setValue([]);
          } else {
            this.formGroup.get('roles').setValue(data.data.map((it) => it.guid));
          }
        }
      });
    });
  }

  removeTask(item: string) {
    const tasks = this.formGroup.get('checklist').value.filter((it) => it.fi !== item);
    this.formGroup.get('checklist').setValue(tasks);
  }

  onVideoUrl(videoUrl: string) {
    this.formGroup.get('model').setValue(videoUrl);
  }

  onImageUrl(imageUrl: string) {
    const images = [...this.formGroup.get('quality').value, imageUrl];
    this.formGroup.get('quality').setValue(images);
  }

  onTask(input: IonInput) {
    const tasks = [...this.formGroup.get('checklist').value, { fi: input.value }];
    this.formGroup.get('checklist').setValue(tasks);
    input.value = '';
  }

  onSave() {
    const value = this.formGroup.value;
    const tickets: string[] = value.tickets ?? [];
    delete value.tickets;
    if (value.guid) {
      this.instructionsService.update(value).then(() => this.saveTicketsLink(value.guid, tickets));
    } else {
      this.instructionsService.save(value).then((data) => this.saveTicketsLink(data.id, tickets));
    }
  }

  dismiss() {
    this._modal.dismiss();
  }
}
