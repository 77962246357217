import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, Validators } from '@angular/forms';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { WorkMachine } from 'src/app/models/work-machine.model';
import { ContractorsService } from 'src/app/services/contractors.service';

@Component({
  standalone: true,
  selector: 'app-work-machine-dialog',
  templateUrl: './work-machine.dialog.html',
  styleUrls: ['./work-machine.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    FormsModule,
    IonicModule,
    MainDialogComponent,
  ],
})
export class WorkMachineDialogComponent {

  formGroup: FormGroup;

  constructor(
    private contractorsService: ContractorsService,
    private formBuilder: FormBuilder,
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    const item: WorkMachine = navParams.get('item');

    this.formGroup = this.formBuilder.group({
      guid: [item?.guid ?? null],
      name: [item?.name ?? null, Validators.required],
      contractor: [item?.contractor ?? this.contractorsService.contractorS()?.guid],
      cost: [item?.cost ?? null],
      period: [item?.period ?? null],
    });
  }

  save() {
    const item: WorkMachine = this.formGroup.value;
    this._modal.dismiss(item);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
