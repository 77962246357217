<app-segment-list [sub]="sub()"
  [segmentValue]="segmentValue()"
  [segmentButtons]="segmentButtons"
  (segmentChanged)="segmentChanged($event)"></app-segment-list>
@if (segmentValue() === 'offers') {
  @if (['partner'].includes(role())) {
    <h2>{{ 'partner.createOffer' | translate }}</h2>
    <div class="ion-margin-top">
      <ion-button color="light"
        (click)="createOffer()">{{ 'general.create' | translate }}</ion-button>
    </div>
  }
  <h2>{{ 'partner.createdOffers' | translate }}</h2>
  @for (offer of offers$ | async; track offer.guid) {
    <ion-item lines="none"
      button="true"
      (click)="onOffer(offer)">
      {{ offer.contractorName }}
      <span slot="end">
        {{ offer.date | timestamp:'d.M.yyyy' }}
      </span>
    </ion-item>
  } @empty {
    <app-no-results></app-no-results>
  }
} @else if (segmentValue() === 'contracts') {
  <h2>{{ 'partner.contracts' | translate }}</h2>
  @for (contract of contracts$ | async; track contract.guid) {
    <ion-item lines="none"
      button="true"
      (click)="onContract(contract)">
      {{ contract.contractorName }}
      <span slot="end">
        {{ contract.date | timestamp:'d.M.yyyy' }}
      </span>
    </ion-item>
  } @empty {
    <app-no-results></app-no-results>
  }
}