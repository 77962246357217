import { DestroyRef, inject, Injectable, signal } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { App } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService, StorageService } from '@scandium-oy/ngx-scandium';
import { getGlobalScope, setExtra, setTag, setUser } from '@sentry/angular';
import { isAfter, startOfToday } from 'date-fns';
import { BehaviorSubject, combineLatest, Observable, of, zip } from 'rxjs';
import { debounceTime, delayWhen, distinctUntilChanged, filter, map, retryWhen, shareReplay, startWith, switchMap, take, tap } from 'rxjs/operators';
import { LocationService } from '../location/location.service';
import { Checklist } from '../models/checklist.model';
import { Client } from '../models/client.model';
import { Contractor } from '../models/contractor.model';
import { ListItem } from '../models/list-item.model';
import { Site } from '../models/site.model';
import { User } from '../models/user.model';
import { storageKeys } from '../utility/constants';
import { Roles } from '../utility/role';
import { getDuration, isTimestamp } from '../utility/time';
import { ChecklistService, translations } from './checklist.service';
import { ClientsService } from './clients.service';
import { ContractorsService } from './contractors.service';
import { MaterialsService } from './materials.service';
import { NavigationService } from './navigation.service';
import { OptionsService } from './options.service';
import { SitesService } from './sites.service';
import { UsersService } from './users.service';

const waitTime = 400;

@Injectable({
  providedIn: 'root',
})
export class InitializeService {
  private destroyRef = inject(DestroyRef);
  private update$ = new BehaviorSubject<void>(null);

  private user$ = this.authService.getUser().pipe(
    switchMap((user) => user != null ? this.usersService.getFromDb(user.uid).pipe(
      debounceTime(500),
      distinctUntilChanged(),
    ) : of<User>(null)),
    debounceTime(waitTime),
    tap((user) => {
      console.info(getDuration(), 'Setting current user', user?.guid);
      if (user == null) {
        this.storageService.remove(storageKeys.user);
      } else {
        this.storageService.set(storageKeys.user, user);
      }
      this.usersService.setCurrentUser(user ?? null);
    }),
    shareReplay(1),
  );

  private retry$ = zip([this.user$, this.contractorsService.getCurrentContractor()]).pipe(
    debounceTime(waitTime),
    filter(([user]) => user != null),
    tap(() => console.info('Retrying')),
  );

  private userAndUpdate$ = combineLatest([this.user$, this.update$]);

  private contractorAndUser$ = combineLatest([
    this.contractorsService.getCurrentContractor().pipe(shareReplay(1)),
    this.user$,
    this.usersService.role$,
  ]).pipe(
    debounceTime(waitTime),
    tap(() => console.info(getDuration(), 'Contractor and user')),
    filter(([contractor, _user, role]) => {
      if ([Roles.superAdmin, Roles.partner, Roles.manager, Roles.calendar].includes(role)) {
        return true;
      } else if (contractor != null) {
        return true;
      }
    }),
    shareReplay(1),
  );

  private contractorAndRole$ = this.contractorAndUser$.pipe(
    filter(([_, user]) => user != null),
    map(([contractor, user, role]) => ({
      role,
      contractor: contractor?.guid,
      worksites: user.worksites,
      clients: contractor?.clients ?? [],
      contractorSites: contractor?.sites.concat(contractor?.archivedSites ?? []) ?? [],
    })),
    shareReplay(1),
  );

  loadingSites = signal(true);

  constructor(
    private authService: AuthService,
    private clientsService: ClientsService,
    private checklistService: ChecklistService,
    private contractorsService: ContractorsService,
    private locationService: LocationService,
    private materialsService: MaterialsService,
    private navigationService: NavigationService,
    private optionsService: OptionsService,
    private sitesService: SitesService,
    private storageService: StorageService,
    private translate: TranslateService,
    private usersService: UsersService,
  ) { }

  update() {
    this.update$.next();
  }

  loadUser(): Observable<boolean> {
    return this.user$.pipe(
      map((user) => {
        if (user) {
          if (user.lang) {
            this.translate.use(user.lang);
          }
          this.storageService.set(storageKeys.role, user.role);
          if (user.role === Roles.worker) {
            this.locationService.checkAndAskPermission();
          }
          setTag('user_mode', user.role);
          if (user.contractors?.length > 0) {
            setExtra('contractor', user.contractors[0]);
          }
          setUser({ id: user.guid });

          if (Capacitor.isNativePlatform()) {
            App.getInfo().then((info) => setTag('version', info.version));
          } else {
            setTag('version', 'desktop');
          }
          if (user.activeEnds != null) {
            let date: Date;
            if (isTimestamp(user.activeEnds)) {
              date = user.activeEnds.toDate();
            } else {
              date = user.activeEnds;
            }
            if (isAfter(startOfToday(), date)) {
              this.navigationService.navigate(['/waiting']);
            }
          }
        } else if (user == null) {
          this.storageService.remove(storageKeys.role);
          this.storageService.clear();
          this.contractorsService.setContractor(null);
          this.sitesService.setSites([]);
          this.clientsService.setClients(null);
          this.usersService.setUsers([]);
          getGlobalScope().clear();
        }
        return user != null;
      }),
      shareReplay(1),
    );
  }

  loadUsers(): Observable<boolean> {
    return this.userAndUpdate$.pipe(
      filter(([user]) => user != null),
      distinctUntilChanged(([prev], [curr]) => {
        return prev?.contractors?.toString() === curr?.contractors?.toString()
          && prev?.role === curr?.role
          && prev?.partners?.toString() === curr?.partners?.toString();
      }),
      switchMap(() => this.updateUsers()),
      shareReplay(1),
    );
  }

  updateUsers() {
    return this.usersService.getCurrentUser().pipe(
      distinctUntilChanged((prev, curr) => prev?.role === curr?.role && prev?.contractors?.toString() === curr?.contractors?.toString()),
      switchMap((user) => {
        let users$: Observable<User[]>;
        if (user) {
          // Set users
          if (user.role === Roles.superAdmin) {
            users$ = this.usersService.getListFromDb();
          } else if ([Roles.manager, Roles.admin, Roles.worker].includes(user.role)) {
            users$ = this.usersService.getMyList();
          } else if (user.role === Roles.partner) {
            users$ = user.partners?.length > 0 ? this.usersService.getListFromDb({ guids: user.partners }) : of([user]);
          } else {
            users$ = of([user]);
          }
        } else {
          users$ = of([]);
        }
        return combineLatest([of(user), users$]);
      }),
      debounceTime(waitTime),
      map(([user, users]) => {
        this.usersService.setUsers(users);
        return user != null;
      }),
    );
  }

  runUpdateUsers(user: User) {
    let users$: Observable<User[]>;
    if (user.role === Roles.superAdmin) {
      users$ = this.usersService.getListFromDb();
    } else if ([Roles.manager, Roles.admin, Roles.worker].includes(user.role)) {
      users$ = this.usersService.getMyList();
    } else if (user.role === Roles.partner) {
      users$ = user.partners?.length > 0 ? this.usersService.getListFromDb({ guids: user.partners }) : of([user]);
    } else {
      users$ = of([user]);
    }
    users$.pipe(take(1)).subscribe((users) => this.usersService.setUsers(users));
  }

  loadContractor(): Observable<boolean> {
    return this.userAndUpdate$.pipe(
      // distinctUntilChanged(([prev], [curr]) => prev?.contractors?.toString() === curr?.contractors?.toString()),
      switchMap(([user]) => {
        return user?.contractors.length > 0 && ![Roles.manager, Roles.partner, Roles.superAdmin, Roles.calendar].includes(user.role)
          ? combineLatest([of(user), this.contractorsService.get(user.contractors[0])]) : of<[User, Contractor]>([user, null]);
      }),
      debounceTime(waitTime),
      map(([user, contractor]) => {
        this.contractorsService.setContractor(contractor);
        if (contractor?.disabled) {
          this.navigationService.navigate(['/waiting']);
        }
        if (contractor?.license === 'free' && [Roles.admin, Roles.worker, Roles.storage].includes(user.role)) {
          this.contractorsService.isSubActive(user, contractor).pipe(
            takeUntilDestroyed(this.destroyRef),
          ).subscribe((isActive) => {
            if (!isActive) {
              this.navigationService.navigate(['/stripe/order']);
            }
          });
        }
        return contractor != null;
      }),
      shareReplay(1),
    );
  }

  loadGlobalOptionsData(): Observable<boolean> {
    return this.user$.pipe(
      distinctUntilChanged((prev, curr) => prev?.role === curr?.role),
      switchMap((user) => {
        if (user == null || user.role === Roles.partner) {
          return combineLatest([this.optionsService.get(), of<Checklist[]>([]), of<Checklist>(null), of<Checklist>(null)]);
        } else if (user.contractors?.length > 0) {
          return combineLatest([
            this.optionsService.get(),
            this.checklistService.getList({ adhocContractor: user.contractors[0] }),
            this.contractorsService.getCurrentContractor().pipe(
              filter((contractor) => contractor?.defaultTicketTemplate != null),
              switchMap((contractor) => this.checklistService.get(contractor?.defaultTicketTemplate)),
              startWith(null),
            ),
            this.checklistService.get(translations),
          ]);
        } else {
          return combineLatest([
            this.optionsService.get(),
            this.checklistService.getList(),
            of<Checklist>(null),
            this.checklistService.get(translations),
          ]);
        }
      }),
      debounceTime(waitTime),
      map(([options, checklists, checklist, translationsList]) => {
        console.info(getDuration(), 'Setting options and checklists');
        this.storageService.set(storageKeys.options, options);
        this.storageService.set(storageKeys.checklists, [...checklists, checklist ?? [], translationsList]);
        return true;
      }),
      shareReplay(1),
    );
  }

  loadClientsData(): Observable<boolean> {
    return this.contractorAndRole$.pipe(
      distinctUntilChanged((prev, curr) => prev?.clients?.toString() === curr?.clients?.toString()),
      switchMap((_) => this.updateClients()),
      retryWhen((errors) =>
        errors.pipe(
          tap((errorMsgs) => console.error(errorMsgs)),
          // Retry when user is set
          delayWhen((_) => this.retry$),
        ),
      ),
      shareReplay(1),
    );
  }

  updateClients() {
    return this.contractorAndRole$.pipe(
      distinctUntilChanged((prev, curr) => prev?.clients?.toString() === curr?.clients?.toString()),
      switchMap((val) => {
        if ([Roles.superAdmin, Roles.manager, Roles.partner, Roles.calendar].includes(val.role) || val.contractor != null) {
          return [Roles.manager, Roles.calendar].includes(val.role) ?
            val.worksites?.length > 0 ?
              this.sitesService.getAdminListFromDb(val.worksites).pipe(
                switchMap((sites) => this.clientsService.getListFromDb(sites
                  .filter((s) => s.client != null)
                  .map((s) => s.client)),
                ),
              ) : of<Client[]>([])
            :
            this.clientsService.getListFromDb([Roles.superAdmin, Roles.partner].includes(val.role) ? null : val?.clients ?? []);
        } else {
          return of<Client[]>([]);
        }
      }),
      debounceTime(waitTime),
      map((clients) => {
        console.info(getDuration(), 'Setting clients', clients?.length);
        this.clientsService.setClients(clients);
        return true;
      }),
    );
  }

  loadSitesData(): Observable<boolean> {
    return combineLatest([this.contractorAndRole$, this.update$.asObservable()]).pipe(
      distinctUntilChanged(([prev], [curr]) => curr?.role !== Roles.manager
        && prev?.contractor === curr?.contractor
        && prev?.role === curr?.role
        && prev?.contractorSites?.toString() === curr?.contractorSites?.toString(),
      ),
      switchMap(([val]) => {
        if ([Roles.superAdmin, Roles.manager, Roles.partner, Roles.calendar].includes(val.role) || val.contractor != null) {
          const siteSet = [Roles.superAdmin, Roles.partner].includes(val.role) ? new Set<string>() :
            [Roles.manager, Roles.worker, Roles.calendar].includes(val.role) ? new Set(val.worksites) : new Set(val.contractorSites) ?? new Set();
          const siteGuids = [Roles.superAdmin, Roles.partner].includes(val.role) ? null : Array.from(siteSet);
          return this.sitesService.getAdminListFromDb(siteGuids);
        } else {
          return of<Site[]>([]);
        }
      }),
      debounceTime(waitTime),
      map((sites) => {
        this.sitesService.setSites(sites);
        this.loadingSites.set(false);
        return true;
      }),
      retryWhen((errors) =>
        errors.pipe(
          // Retry when user is set
          delayWhen((_) => this.retry$),
        ),
      ),
      shareReplay(1),
    );
  }

  loadMaterialsData(): Observable<boolean> {
    return this.contractorAndRole$.pipe(
      distinctUntilChanged((prev, curr) => prev?.contractor === curr?.contractor),
      switchMap((val) => {
        if (![Roles.superAdmin, Roles.manager, Roles.partner, Roles.calendar].includes(val?.role) && val?.contractor != null) {
          return this.materialsService.getListFromDb({ contractor: val?.contractor });
        } else {
          return of<ListItem[]>([]);
        }
      }),
      debounceTime(waitTime),
      map((materials) => {
        console.info(getDuration(), 'Setting materials');
        this.storageService.set(storageKeys.materials, materials);
        return true;
      }),
      shareReplay(1),
    );
  }
}
