import { CommonModule } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { Timestamp } from '@angular/fire/firestore';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { Browser } from '@capacitor/browser';
import { Dialog } from '@capacitor/dialog';
import { IonicModule, IonModal, IonPopover, ModalController, NavParams } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { startOfToday } from 'date-fns';
import { AppCommonModule } from 'src/app/common.module';
import { ContractorOffer } from 'src/app/models/contractor-offer.model';
import { UsersService } from 'src/app/services/users.service';
import { Languages, LANGUAGES } from 'src/app/utility/constants';
import { getDateWithTimezone } from 'src/app/utility/time';
import { MainDialogComponent } from '../../main-dialog/main-dialog.component';
import { SelectDayComponent } from '../../select-day/select-day.component';
import { PrintOfferComponent } from './print/print-offer.component';

@Component({
  standalone: true,
  selector: 'app-contractor-offer-dialog',
  templateUrl: './contractor-offer.dialog.html',
  styleUrls: ['./contractor-offer.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    MainDialogComponent,
    SelectDayComponent,
    PrintOfferComponent,
  ],
})
export class ContractorOfferDialogComponent {
  private formBuilder = inject(FormBuilder);
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);
  private translate = inject(TranslateService);
  private usersService = inject(UsersService);

  private currentItem = this.navParams.get<ContractorOffer>('item');

  readonly terms = ['indefinetly', '12', '24', '36', '48'];
  readonly langs = this.translate.langs as `${Languages}`[];

  formGroup = this.formBuilder.group({
    guid: [this.currentItem?.guid ?? null],
    date: [this.currentItem?.date ? getDateWithTimezone(this.currentItem.date) : getDateWithTimezone(startOfToday()), Validators.required],
    contractorName: [this.currentItem?.contractorName ?? '', Validators.required],
    businessId: [this.currentItem?.businessId ?? '', Validators.required],
    address: this.formBuilder.group({
      street: [this.currentItem?.address.street ?? '', Validators.required],
      postalCode: [this.currentItem?.address.postalCode ?? '', Validators.required],
      city: [this.currentItem?.address.city ?? '', Validators.required],
    }),
    eAddresses: this.formBuilder.array(this.currentItem != null ? this.currentItem.eAddresses.map((it) => this.formBuilder.control(it)) : []),
    contacts: this.formBuilder.array(
      this.currentItem != null ?
        this.currentItem.contacts.map((it) => this.formBuilder.group({
          name: [it.name, Validators.required],
          phone: [it.phone, Validators.required],
          email: [it.email, [Validators.required, Validators.email]],
        }))
        : []
    ),
    costs: this.formBuilder.group({
      basic: [this.currentItem?.costs.basic ?? 0, Validators.required],
      admin: [this.currentItem?.costs.admin ?? 0, Validators.required],
      supervisor: [this.currentItem?.costs.supervisor ?? 0, Validators.required],
      worker: [this.currentItem?.costs.worker ?? 0, Validators.required],
      adminQty: [this.currentItem?.costs.adminQty ?? 0, Validators.required],
      supervisorQty: [this.currentItem?.costs.supervisorQty ?? 0, Validators.required],
      workerQty: [this.currentItem?.costs.workerQty ?? 0, Validators.required],
    }),
    start: [this.currentItem?.start ? getDateWithTimezone(this.currentItem.start) : getDateWithTimezone(startOfToday()), Validators.required],
    term: [this.currentItem?.term ?? this.terms[0], Validators.required],
    offerUrl: [this.currentItem?.offerUrl ?? null],
    contractUrl: [this.currentItem?.contractUrl ?? null],
    lang: [this.currentItem?.lang ?? LANGUAGES[0]],
  });

  savingItem = signal<ContractorOffer>(null);

  getEAddresses() {
    return this.formGroup.get('eAddresses') as FormArray;
  }

  addEAddressItem() {
    this.getEAddresses().push(this.formBuilder.control(''));
  }

  deleteEAddressItem(index: number) {
    this.getEAddresses().removeAt(index);
  }

  getContacts() {
    return this.formGroup.get('contacts') as FormArray;
  }

  addContactItem() {
    this.getContacts().push(this.formBuilder.group({
      name: ['', Validators.required],
      phone: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
    }));
  }

  deleteContactItem(index: number) {
    this.getContacts().removeAt(index);
  }

  onDateChange(date: string, field = 'date') {
    this.formGroup.get(field).setValue(date);
  }

  selectLang(lang: `${Languages}`, langPopover: IonPopover | IonModal) {
    this.formGroup.get('lang').setValue(lang);
    langPopover?.dismiss();
  }

  openOffer(url: string) {
    Browser.open({ url });
  }

  toContract() {
    this.save('accepted');
  }

  save(status = 'created') {
    const user = this.usersService.currentUserS();
    const value = this.formGroup.value;
    const item = (Object.assign({}, value) as unknown) as ContractorOffer;
    item.date = new Date(value.date);
    item.start = new Date(value.start);
    item.partner = { guid: user.guid, name: user.displayName };
    if (item.guid == null) {
      item.created = Timestamp.now();
    }
    item.status = status as 'created' | 'deleted' | 'accepted';
    Dialog.prompt({
      message: item.status === 'created' ? this.translate.instant('offer.giveCustomerEmail') : this.translate.instant('offer.giveCustomerEmailContract'),
    }).then((confirmValue) => {
      if (confirmValue?.value?.length > 0) {
        item.customerEmail = confirmValue.value;
        this.savingItem.set(item);
      }
    });
  }

  saved(url: string) {
    const item = this.savingItem();
    if (item.status === 'created') {
      item.offerUrl = url;
    } else if (item.status === 'accepted') {
      item.contractUrl = url;
    }
    this._modal.dismiss(item);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
