import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';
import { MainDialogComponent } from '../main-dialog/main-dialog.component';
import { NoResultsComponent } from '../no-results/no-results.component';
import { AttachmentsComponent } from './attachments.component';

@Component({
  standalone: true,
  selector: 'app-attachments-dialog',
  templateUrl: './attachments.dialog.html',
  styleUrls: ['./attachments.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    NoResultsComponent,
    AttachmentsComponent,
    MainDialogComponent,
  ],
})
export class AttachmentsDialogComponent {

  attachments = signal<string[]>([]);
  isReadOnly = signal(false);

  constructor(
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    const items = navParams.get<string[]>('items') ?? [];
    this.attachments.set(items);
    this.isReadOnly.set(navParams.get('readonly') ?? false);
  }

  save(items: string[]) {
    this._modal.dismiss(items);
  }

  dismiss() {
    this._modal.dismiss();
  }
}
