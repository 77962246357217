<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    @if (existing) {
      {{ existing.name }}
    } @else {
      {{ 'materials.new' | translate }}
    }
  </div>
  <form [formGroup]="formGroup"
    (ngSubmit)="save()">
    @if (formGroup.get('img')?.value; as imgUrl) {
      <app-images [images]="[imgUrl]"></app-images>
      <ion-icon slot="end"
        color="danger"
        name="remove-circle-outline"
        (click)="removeImage()"></ion-icon>
    } @else {
      <app-camera-button class="only-camera"
        [name]="imgName"
        color="light"
        fill="clear"
        [multiline]="true"
        shape="round"
        (imageUrl)="onImageUrl($event)"></app-camera-button>
    }
    <h4>{{ 'materials.name' | translate }}</h4>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="name"></ion-input>
    </ion-item>
    <h4>{{ 'materials.price' | translate }}</h4>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="price"
        type="number"></ion-input>
    </ion-item>
    <h4>{{ 'materials.retailPrice' | translate }}</h4>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="retailPrice"
        type="number"></ion-input>
    </ion-item>
    <h4>{{ 'materials.storageAmount' | translate }}</h4>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="amount"
        type="number"></ion-input>
    </ion-item>
    <h4>{{ 'materials.category' | translate }}</h4>
    @if (categories$ | async; as categories) {
      <ion-item lines="none">
        <ion-select aria-label=""
          label-placement="stacked"
          [placeholder]="'general.select' | translate"
          interface="popover"
          formControlName="category"
          (ionChange)="onCategory()">
          <ion-select-option [value]="null">
            {{ 'materials.newCategory' | translate }}
          </ion-select-option>
          @for (category of categories; track category) {
            <ion-select-option [value]="category.name">
              {{ category.name }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
    }
    <div class="ion-margin-top">
      <ion-button color="light"
        shape="round"
        expand="full"
        type="submit"
        [disabled]="!formGroup.valid">{{ 'general.save' | translate }}</ion-button>
    </div>
  </form>
</app-main-dialog>