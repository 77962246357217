import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { environment } from 'src/environments/environment';
import { isRoleGuard } from './guards/is-role.guard';
import { isSiteleadGuard } from './guards/sitelead.guard';
import { InvitesPage } from './invites/invites.page';
import { InvoicingPage } from './invoicing/invoicing.page';
import { PartnerNotesPage } from './notes/partner/partner-notes.page';
import { OffersPage } from './offers/offers.page';
import { PrivacyPage } from './privacy/privacy.page';
import { StripeCancelPage } from './stripe/cancel/cancel.page';
import { StripeOrderPage } from './stripe/order/order.page';
import { StripeSuccessPage } from './stripe/success/success.page';
import { TimesheetsPage } from './timesheets/timesheets.page';
import { UserWeekCalendarPageComponent } from './user-week-calendar/user-week-calendar.page';
import { Roles } from './utility/role';
import { WorkMachinesPage } from './work-machines/work-machines.page';

const routes: Routes = [
  {
    path: 'calendar',
    loadChildren: async () =>
      import('./calendar/calendar.routing.module'),
  },
  {
    path: 'clients',
    loadChildren: async () =>
      import('./clients/clients.routing.module'),
  },
  {
    path: 'contractors',
    loadChildren: async () =>
      import('./contractors/contractors.routing.module'),
  },
  {
    path: 'dashboard',
    loadChildren: async () =>
      import('./dashboard/dashboard.routing.module'),
  },
  {
    path: 'efficiency',
    loadChildren: async () => import('./efficiency/efficiency.routing.module'),
  },
  {
    path: 'feedback',
    loadChildren: async () =>
      import('./feedback/feedback.routing.module'),
  },
  {
    path: 'info',
    loadChildren: async () =>
      import('./info/info.module'),
  },
  {
    path: 'partner/invites',
    component: InvitesPage,
  },
  {
    path: 'partner/notes',
    component: PartnerNotesPage,
    canActivate: [isRoleGuard([Roles.admin, Roles.superAdmin, Roles.partner])],
  },
  {
    path: 'partner/offers',
    component: OffersPage,
    canActivate: [isRoleGuard([Roles.superAdmin, Roles.partner])],
  },
  {
    path: 'privacy',
    component: PrivacyPage,
  },
  {
    path: 'invoice',
    loadChildren: async () =>
      import('./invoice/invoice.module'),
  },
  {
    path: 'kanban-dashboard',
    loadChildren: async () =>
      import('./kanban/dashboard/kanban-dashboard.routing.module'),
  },
  {
    path: 'home',
    loadChildren: async () =>
      import('./home/home.routing.module'),
  },
  {
    path: 'instructions',
    loadChildren: async () =>
      import('./instructions/instructions.routing.module'),
  },
  {
    path: 'invoicing',
    component: InvoicingPage,
    canActivate: [isRoleGuard([Roles.superAdmin])],
  },
  {
    path: 'lastplanner',
    loadChildren: async () =>
      import('./lastplanner/lastplanner.routing.module'),
  },
  {
    path: 'littera',
    loadChildren: async () =>
      import('./littera/littera.module'),
  },
  {
    path: 'login',
    loadChildren: async () =>
      import('./login/login.module'),
  },
  {
    path: 'logs',
    loadChildren: async () =>
      import('./logs/logs.module'),
  },
  {
    path: 'materials',
    loadChildren: async () =>
      import('./materials/materials.module'),
  },
  {
    path: 'merge-users',
    loadChildren: async () =>
      import('./merge-users/merge-users.module'),
  },
  {
    path: 'news',
    loadChildren: async () =>
      import('./news/news.routing.module'),
  },
  {
    path: 'options',
    loadChildren: async () =>
      import('./options/options.module'),
  },
  {
    path: 'paycheck',
    loadChildren: async () =>
      import('./paycheck/paycheck.module'),
  },
  {
    path: 'profile',
    loadChildren: async () =>
      import('./profile/profile.module'),
  },
  {
    path: 'profit',
    loadChildren: async () =>
      import('./profit/profit.module'),
  },
  {
    path: 'register',
    loadChildren: async () =>
      import('./register/register.routing.module'),
  },
  {
    path: 'report/locations',
    loadChildren: async () =>
      import('./report/locations/locations.module'),
  },
  {
    path: 'report/site',
    loadChildren: async () =>
      import('./report/site/report.module'),
  },
  {
    path: 'report/user',
    loadChildren: async () =>
      import('./report/user/report-user.module'),
  },
  {
    path: 'schedule',
    loadChildren: async () =>
      import('./schedule/schedule.module'),
  },
  {
    path: 'sign-up',
    loadChildren: async () =>
      import('./on-boarding/on-boarding.routing.module'),
  },
  {
    path: 'sites',
    loadChildren: async () =>
      import('./sites/sites.routing.module'),
  },
  {
    path: 'stripe/order',
    component: StripeOrderPage,
  },
  {
    path: 'stripe/success',
    component: StripeSuccessPage,
  },
  {
    path: 'stripe/cancel',
    component: StripeCancelPage,
  },
  {
    path: 'templates',
    loadChildren: async () =>
      import('./templates/templates.module'),
  },
  {
    path: 'timesheets',
    component: TimesheetsPage,
    canActivate: [isSiteleadGuard()],
  },
  {
    path: 'users',
    loadChildren: async () =>
      import('./users/users.routing.module'),
  },
  {
    path: 'user-week-calendar',
    component: UserWeekCalendarPageComponent,
  },
  {
    path: 'week-user',
    loadChildren: async () => import('./kanban/user-week-view/user-week-view.routing-module'),
  },
  {
    path: 'waiting',
    loadChildren: async () =>
      import('./waiting/waiting.module'),
  },
  {
    path: 'worker',
    loadChildren: async () =>
      import('./worker/worker.module'),
  },
  {
    path: 'working-calendar',
    loadChildren: async () =>
      import('./working-calendar/working-calendar.routing.module'),
  },
  {
    path: 'work-machines',
    component: WorkMachinesPage,
    canActivate: [isRoleGuard([Roles.admin, Roles.superAdmin])],
  },
  {
    path: '',
    redirectTo: environment.home,
    pathMatch: 'full',
  },
];
@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      bindToComponentInputs: true,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule { }
