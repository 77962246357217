<app-main-page>
  <div desktopTitle>
  </div>
  <div class="logo">
    <img src="./assets/images/logo.png">
  </div>
  <p>{{ 'stripe.cancel' | translate }}</p>
  @if (params$ | async) {
  }
  <div class="ion-margin-top">
    <ion-button color="light"
      (click)="home()">
      {{ 'general.next' | translate }}
    </ion-button>
  </div>
</app-main-page>