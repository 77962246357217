import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from '../common.module';
import { MainPageComponent } from '../components/main-page/main-page.component';
import { OffersComponent } from '../components/partner-home/offer/offers/offers.component';

@Component({
  standalone: true,
  selector: 'app-offers-page',
  templateUrl: './offers.page.html',
  styleUrls: ['./offers.page.scss'],
  imports: [
    AppCommonModule,
    IonicModule,
    CommonModule,
    MainPageComponent,
    OffersComponent,
  ],
})
export class OffersPage {

}
