import { CommonModule } from '@angular/common';
import { CUSTOM_ELEMENTS_SCHEMA, Component, EventEmitter, Output, input } from '@angular/core';
import { IonicModule, IonicSlides, ModalController } from '@ionic/angular';
import { CameraButtonModule, ImageDialogComponent } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { memo } from 'src/app/utility/memo';
import { TakeVideoComponent } from '../take-video/take-video.component';

@Component({
  standalone: true,
  selector: 'app-images',
  templateUrl: './images.component.html',
  styleUrls: ['./images.component.scss'],
  imports: [
    CommonModule,
    IonicModule,
    AppCommonModule,
    CameraButtonModule,
    TakeVideoComponent,
    ImageDialogComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ImagesComponent {

  showCamera = input<string>(null);
  showVideo = input(false);
  images = input<string[]>([]);
  videos = input<string[]>([]);
  showDetails = input<boolean>(false);
  canDelete = input<boolean>(false);
  onlyThumbs = input<boolean>(false);
  wrap = input<'unset' | 'wrap'>('unset');

  @Output()
  deleted = new EventEmitter<string>();

  @Output()
  openCamera = new EventEmitter<string>();

  @Output()
  openVideo = new EventEmitter<string>();

  getDetails = memo((image: string) => this._getDetails(image));
  swiperModules = [IonicSlides];

  constructor(
    private modalCtrl: ModalController,
  ) { }

  private _getDetails(image: string): string {
    const path = image.split('?')[0];
    const part = path.split('/')[path.split('/').length - 1];
    const [, year, month, date] = part.split('-');
    const day = date.split('T')[0];
    return `${day}.${month}.${year}`;
  }

  onImageUrl(imageUrl: string) {
    this.openCamera.emit(imageUrl);
  }

  onVideoUrl(videoUrl: string) {
    this.openVideo.emit(videoUrl);
  }

  openImage(image: string) {
    this.modalCtrl.create({
      component: ImageDialogComponent,
      componentProps: { image, images: this.images() },
      cssClass: ['modal-fullscreen', 'transparent-modal'],
    }).then((m) => {
      m.present();
    });
  }

  delete(image: string) {
    this.deleted.emit(image);
  }
}
