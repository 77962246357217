<app-main-page>
  <div desktopTitle>
  </div>
  <h1>{{ 'stripe.success' | translate }}</h1>
  @if ({
   params: params$ | async,
   subscription: subscription$ | async,
   user: user$ | async
  }; as vm) {
    @if (vm.user && vm.subscription) {
      <div class="grid">
        <div>{{ 'stripe.username' | translate }}</div>
        <div>{{ vm.user.email }}</div>
        <div>{{ 'users.role' | translate }}</div>
        <div>{{ 'users.roles.' + vm.user.role | translate }}</div>
        <div class="ion-margin-top">{{ 'stripe.start' | translate }}</div>
        <div class="ion-margin-top">{{ vm.subscription.current_period_start | timestamp:'d.M.yyyy' }}</div>
        <div>{{ 'stripe.indefinitely' | translate }}</div>
        <div></div>
        <div class="ion-margin-top">{{ 'stripe.price' | translate }}</div>
        <div class="ion-margin-top">
          {{ (vm.subscription.items[0].price.unit_amount / 100) | currency:'EUR' }}
          {{ 'contractor.perMonthOnly' | translate }} ({{ 'stripe.vat' | translate }} 0%)
        </div>
        <div>{{ 'stripe.price' | translate }}</div>
        <div>
          {{ priceWithVat().price | currency:'EUR' }}
          {{ 'contractor.perMonthOnly' | translate }} ({{ 'stripe.vat' | translate }} {{ priceWithVat().percentage }}%)
        </div>
      </div>
      } @else {
        <div>
          <app-loading></app-loading>
        </div>
      }
      }
      <div class="ion-margin-top">
        <ion-button color="light"
          (click)="home()">
          {{ 'general.done' | translate }}
        </ion-button>
      </div>
</app-main-page>