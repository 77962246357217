export const css = `
  #print-offer {
    font-family: Arial, sans-serif;
    padding: 0;
    color: #333;
    margin: 0;
    background-color: #fff;
    width: 210mm;
  }
  header {
    text-align: left;
    margin-bottom: 20px;
  }
  header h1 {
    font-size: 110px;
    text-transform: uppercase;
    margin: 300px 0px 20px 0px;
    color: #0279ad;
    font-family: "Verdana";
    letter-spacing: -8px;
    font-weight: bold;
  }
  .section h3 {
    font-size: 16px;
    margin-bottom: 10px;
    padding-bottom: 5px;
  }
  table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  table th, table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: left;
  }
  table th {
    background-color: #f4f4f4;
    font-size: 12px;
    font-weight: bold;
  }
  table td {
    font-size: 16px;
  }
  .footer {
    text-align: center;
    font-size: 12px;
    color: #666;
    margin-top: 20px;
  }
  .page, .page-cover {
    width: 210mm;
    height: 297mm; /* 297mm */
    margin: 0;
    background: #fff;
    padding: 20mm 20mm;
  }
  .page h2 {
    font-size: 32px;
    font-family: "Verdana";
    letter-spacing: -2px;
    margin: 0 0 30px 0;
    color: #0279ad;
  }
  .page h3 {
    font-size: 18px;
    font-family: verdana;
    margin-bottom: 12px;
    color: #000;
  }
  .page-cover h2 {
    font-size: 24px;
    font-family: "Verdana";
    letter-spacing: -2px;
    margin: 0 0 30px 0;
    color: #0279ad;
  }
  .page-cover h3 {
    font-size: 18px;
    color: #aacade;
    margin-bottom: 150px;
  }
`;
