import { MenuItem } from './menu.item.model';

export const items: MenuItem[] = [
  {
    icon: 'hammer',
    name: 'nav.contractors',
    route: '/contractors',
  },
  {
    icon: 'newspaper',
    name: 'nav.news',
    route: '/worker/messages',
  },
  {
    icon: 'card-outline',
    name: 'nav.invites',
    route: '/partner/invites',
  },
  {
    icon: 'newspaper-outline',
    name: 'nav.notes',
    route: '/partner/notes',
  },
  {
    icon: 'cash-outline',
    name: 'nav.sales',
    route: '/partner/offers',
  },
];

export const partnerMenu = {
  items,
};
