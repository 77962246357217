import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { where } from '@angular/fire/firestore';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { ContractorOffer } from '../models/contractor-offer.model';
import { IService } from '../models/service.interface';
import { FirestoreService } from './firestore.service';

const url = `${environment.backendUrl}/offers`;
const itemCollection = 'contractor-offers';

@Injectable({
  providedIn: 'root',
})
export class ContractorOffersService implements IService<ContractorOffer> {
  private http = inject(HttpClient);

  constructor(private firestore: FirestoreService) { }

  async save(item: ContractorOffer) {
    return this.firestore.save(itemCollection, item);
  }

  async update(item: ContractorOffer) {
    return this.firestore.update(itemCollection, item);
  }

  async remove(item: ContractorOffer) {
    return this.firestore.delete(itemCollection, item);
  }

  get(guid: string) {
    return this.firestore.get<ContractorOffer>(itemCollection, guid);
  }

  getList(options?: { partner?: string; status?: string }) {
    const queryConstraints = [];
    if (options?.partner) {
      queryConstraints.push(where('partner.guid', '==', options.partner));
    }
    if (options?.status) {
      queryConstraints.push(where('status', '==', options.status));
    }

    return this.firestore
      .getList<ContractorOffer>(itemCollection, undefined, queryConstraints);
  }

  sendOffer(guid: string, fileUrl: string, email: string) {
    if (environment.backendUrl) {
      return this.http.post(`${url}/email`, { guid, url: fileUrl, email }).pipe(
        map((res) => res),
      );
    } else {
      console.error('No backend url');
      return null;
    }
  }
}
