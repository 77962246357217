import { CommonModule } from '@angular/common';
import { Component, inject } from '@angular/core';
import { IonSearchbar, IonicModule, ModalController, NavParams } from '@ionic/angular';
import { BehaviorSubject, combineLatest, of } from 'rxjs';
import { filter, map, shareReplay, switchMap } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { Classification } from 'src/app/models/classifications.model';
import { ClassificationsService } from 'src/app/services/classifications.service';
import { sortByNumber } from 'src/app/utility/sort';
import { MainDialogComponent } from '../main-dialog/main-dialog.component';
import { NoResultsComponent } from '../no-results/no-results.component';

@Component({
  standalone: true,
  selector: 'app-select-littera-dialog',
  templateUrl: './select-littera.dialog.html',
  styleUrls: ['./select-littera.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    NoResultsComponent,
    MainDialogComponent,
  ],
})
export class SelectLitteraDialogComponent {
  private classificationsService = inject(ClassificationsService);
  private _modal = inject(ModalController);
  private navParams = inject(NavParams);

  private filter$ = new BehaviorSubject('');

  classification = this.navParams.get('classification');
  private allLitteras$ = of(this.classification).pipe(
    filter((classification) => classification != null),
    switchMap((classification) => this.classificationsService.get(classification)),
    map((item) => {
      item.codes.sort((a, b) => sortByNumber(a, b, 'code'));
      item.codes.map((code) => this.classificationsService.setFullCodes(code, null)).map((code) => this.classificationsService.sortChildren(code));
      return item;
    }),
    map((item) => this.classificationsService.getChildren(item)),
  );

  litteras$ = combineLatest([this.allLitteras$, this.filter$.asObservable()]).pipe(
    map(([items, filterValue]) => items.filter((it) => it.fullCode.toLowerCase().includes(filterValue.toLowerCase()) || it.name.toLowerCase().includes(filterValue.toLowerCase()))),
    shareReplay(1),
  );

  clearButton = this.navParams.get('clearButton');

  onFilter(input: IonSearchbar) {
    const value = input.value;
    this.filter$.next(value);
  }

  select(item: Classification) {
    const { fullCode, name } = item;
    this._modal.dismiss({ code: fullCode, name });
  }

  clear() {
    this._modal.dismiss({ clear: true });
  }

  dismiss() {
    this._modal.dismiss();
  }
}
