const hasDifferentArgs = (prev: unknown[], next: unknown[]): boolean => {
  if (prev.length !== next.length) {
    return true;
  }
  for (let i = 0; i < prev.length; i++) {
    if (!Object.is(prev[i], next[i])) {
      return true;
    }
  }
  return false;
};


export const memo = <T extends Function>(fnToMemoize: T): T => {
  let prevArgs = [{}];
  let result: T;

  // eslint-disable-next-line @stylistic/ts/space-before-function-paren
  return function (...newArgs: any[]) {
    if (hasDifferentArgs(prevArgs, newArgs)) {
      result = fnToMemoize(...newArgs);
      prevArgs = newArgs;
    }
    return result;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
  } as any;
};
