import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { IonicModule } from '@ionic/angular';
import { AppCommonModule } from 'src/app/common.module';

@Component({
  standalone: true,
  selector: 'app-no-results',
  templateUrl: './no-results.component.html',
  styleUrls: ['./no-results.component.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
  ],
})
export class NoResultsComponent {

}
