import { CommonModule } from '@angular/common';
import { Component, computed, signal } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { CameraButtonModule, FileUpload } from '@scandium-oy/ngx-scandium';
import { QueueItem } from '@scandium-oy/ngx-scandium/lib/services/queue.service';
import { AppCommonModule } from 'src/app/common.module';
import { ImagesComponent } from 'src/app/components/images/images.component';
import { MainDialogComponent } from 'src/app/components/main-dialog/main-dialog.component';
import { TakeVideoComponent } from 'src/app/components/take-video/take-video.component';
import { maxImages } from 'src/app/utility/ticket';

@Component({
  standalone: true,
  selector: 'app-add-photos-dialog',
  templateUrl: './add-photos.dialog.html',
  styleUrls: ['./add-photos.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    CameraButtonModule,
    TakeVideoComponent,
    ImagesComponent,
    MainDialogComponent,
  ],
})
export class AddphotosDialogComponent {
  private initialMediaItems: number;

  readonly maxImages = maxImages;

  items = signal<(QueueItem<FileUpload> & { src: string })[]>([]);

  images = signal<string[]>([]);
  videos = signal<string[]>([]);

  disableAddingMedia = computed(() => {
    if (this.isRequired() && (this.images().length === 0 && this.videos().length === 0 && this.items().length === 0)) {
      return false;
    }
    return (this.initialMediaItems + this.images().length + this.videos().length) >= maxImages || (this.initialMediaItems + this.items.length >= maxImages);
  });

  isRequired = signal(false);

  cb: (imageUrl: string) => void;

  constructor(
    private _modal: ModalController,
    navParams: NavParams,
  ) {
    this.isRequired.set(navParams.get<boolean>('required') ?? false);
    this.initialMediaItems = navParams.get<number>('mediaItems') ?? 0;
    this.cb = navParams.get('cb') ?? null;
  }

  onImageUrl(imageUrl: string) {
    this.images.update((val) => [...val, imageUrl]);
  }

  onQueueItem(queueItem: QueueItem<FileUpload>) {
    const item = Object.assign({}, queueItem, { src: URL.createObjectURL(queueItem.item.file) });
    this.items.update((val) => [...val, item]);
  }

  videoUrl(videoUrl: string) {
    this.videos.update((val) => [...val, videoUrl]);
  }

  dismiss(cancel = false) {
    if (cancel) {
      this._modal.dismiss(null, 'cancel');
    } else {
      this._modal.dismiss({ images: this.images(), videos: this.videos(), items: this.items() });
    }
  }
}
