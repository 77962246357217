import { HttpClient } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

const url = `${environment.backendUrl}/openai`;

export interface AiTask {
  name: string;
  description: string;
  duration: string;
  order: string;
  area?: string;
  room?: string;
  deadline?: string;
  user?: string;
  contractor?: string;
}

export interface AiBuilding {
  name: string;
  size?: number;
  children?: AiBuilding[];
}

@Injectable({
  providedIn: 'root',
})
export class AiService {
  private http = inject(HttpClient);

  doTasks(description: string, site?: string, onlyOne = true) {
    if (environment.backendUrl) {
      return this.http.post(`${url}/tasks`, { description, site, onlyOne }).pipe(
        map((res) => {
          if (res == null) {
            return Array.from<AiTask>([]);
          }
          const data = res['data'];
          if (Array.isArray(data)) {
            return data as AiTask[];
          }
          if ('tasks' in data) {
            return data['tasks'] as AiTask[];
          }
        }),
      );
    } else {
      return of([]);
    }
  }

  transcript(audioUrl: string) {
    if (environment.backendUrl) {
      return this.http.post<{ data: string }>(`${url}/transcript`, { url: audioUrl });
    } else {
      return of({ data: '' });
    }
  }

  createRooms(description: string): Observable<{ items: AiBuilding[]; error?: unknown }> {
    if (environment.backendUrl) {
      return this.http.post(`${url}/createRooms`, { description }).pipe(
        map((res) => {
          return { items: res['data']?.['items'] as AiBuilding[] ?? [], error: res['error'] };
        }),
      );
    } else {
      return of({ items: [] });
    }
  }
}
