<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'partner.createOffer' | translate }}
  </div>
  @if (savingItem()) {
    <app-print-offer [item]="savingItem()"
      (printed)="saved($event)"></app-print-offer>
  } @else {
    <form [formGroup]="formGroup">
      <app-select-day color="secondary"
        [id]="'offerCreationDay'"
        [name]="'tickets.date'"
        size="medium"
        [value]="formGroup.get('date').value"
        (changed)="onDateChange($event)"></app-select-day>
      <h4>{{ 'signup.businessName' | translate }}</h4>
      <ion-item lines="none">
        <ion-input autocapitalize="on"
          formControlName="contractorName"></ion-input>
      </ion-item>
      <h4>{{ 'signup.businessId' | translate }}</h4>
      <ion-item lines="none">
        <ion-input autocapitalize="on"
          formControlName="businessId"></ion-input>
      </ion-item>
      <div class="divider"></div>
      <form class="ion-margin-top"
        formGroupName="address">
        <h6>{{ 'register.form.street' | translate }}</h6>
        <ion-item lines="none">
          <ion-input autocapitalize="on"
            aria-label=""
            formControlName="street"></ion-input>
        </ion-item>
        <div class="flex">
          <div>
            <h6>{{ 'register.form.postalCode' | translate }}</h6>
            <ion-item lines="none">
              <ion-input aria-label=""
                formControlName="postalCode"></ion-input>
            </ion-item>
          </div>
          <div>
            <h6>{{ 'register.form.city' | translate }}</h6>
            <ion-item lines="none">
              <ion-input autocapitalize="on"
                aria-label=""
                formControlName="city"></ion-input>
            </ion-item>
          </div>
        </div>
      </form>
      <div class="divider"></div>
      <form formArrayName="eAddresses">
        <h4>{{ 'offer.eaddresses' | translate }}</h4>
        @for (item of getEAddresses().controls; track item; let i = $index) {
          <ion-item lines="none">
            <ion-input autocapitalize="on"
              [formControlName]="i"></ion-input>
            <ion-icon slot="end"
              color="danger"
              name="remove-circle-outline"
              (click)="deleteEAddressItem(i)"></ion-icon>
          </ion-item>
        }
      </form>
      <div class="ion-margin-top">
        <ion-button color="secondary"
          shape="round"
          (click)="addEAddressItem()">
          <ion-icon slot="start"
            name="add-outline"></ion-icon>
          {{ 'item.addRow' | translate }}
        </ion-button>
      </div>
      <div class="divider"></div>
      <form formArrayName="contacts">
        <h4>{{ 'offer.contacts' | translate }}</h4>
        @for (item of getContacts().controls; track item; let i = $index) {
          <div [formGroupName]="i">
            <ion-item lines="none">
              <ion-input autocapitalize="on"
                [placeholder]="'users.displayName' | translate"
                formControlName="name"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-input [placeholder]="'users.phone' | translate"
                formControlName="phone"></ion-input>
            </ion-item>
            <ion-item lines="none">
              <ion-input [placeholder]="'users.email' | translate"
                formControlName="email"></ion-input>
            </ion-item>
            <ion-button color="danger"
              (click)="deleteContactItem(i)">
              {{ 'general.delete' | translate }}
            </ion-button>
          </div>
        }
      </form>
      <div class="ion-margin-top">
        <ion-button color="secondary"
          shape="round"
          (click)="addContactItem()">
          <ion-icon slot="start"
            name="add-outline"></ion-icon>
          {{ 'item.addRow' | translate }}
        </ion-button>
      </div>
      <div class="divider"></div>
      <h4>{{ 'offer.costsTitle' | translate }}</h4>
      <form class="ion-margin-top"
        formGroupName="costs">
        <h6>{{ 'offer.costs.basic' | translate }}</h6>
        <ion-item lines="none">
          <ion-input type="number"
            aria-label=""
            formControlName="basic"></ion-input>
        </ion-item>
        <h6>{{ 'users.roles.admin' | translate }}</h6>
        <div class="flex">
          <ion-item lines="none">
            <ion-input type="number"
              aria-label=""
              formControlName="admin">
              <span slot="end">{{ 'contractor.perMonth' | translate }}</span>
            </ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-input type="number"
              aria-label=""
              formControlName="adminQty">
              <span slot="end">{{ 'sites.workers.qty' | translate }}</span>
            </ion-input>
          </ion-item>
        </div>
        <h6>{{ 'users.roles.supervisor' | translate }}</h6>
        <div class="flex">
          <ion-item lines="none">
            <ion-input type="number"
              aria-label=""
              formControlName="supervisor">
              <span slot="end">{{ 'contractor.perMonth' | translate }}</span>
            </ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-input type="number"
              aria-label=""
              formControlName="supervisorQty">
              <span slot="end">{{ 'sites.workers.qty' | translate }}</span>
            </ion-input>
          </ion-item>
        </div>
        <h6>{{ 'users.roles.worker' | translate }}</h6>
        <div class="flex">
          <ion-item lines="none">
            <ion-input type="number"
              aria-label=""
              formControlName="worker">
              <span slot="end">{{ 'contractor.perMonth' | translate }}</span>
            </ion-input>
          </ion-item>
          <ion-item lines="none">
            <ion-input type="number"
              aria-label=""
              formControlName="workerQty">
              <span slot="end">{{ 'sites.workers.qty' | translate }}</span>
            </ion-input>
          </ion-item>
        </div>
      </form>
      <div class="divider"></div>
      <h4>{{ 'offer.term' | translate }}</h4>
      <app-select-day color="secondary"
        [id]="'offerStartDay'"
        [name]="'sites.workers.startDate'"
        size="medium"
        [value]="formGroup.get('start').value"
        (changed)="onDateChange($event, 'start')"></app-select-day>
      <ion-item lines="none">
        <ion-select aria-label=""
          label-placement="stacked"
          [placeholder]="'general.select' | translate"
          interface="popover"
          formControlName="term">
          @for (term of terms; track term) {
            <ion-select-option [value]="term">
              {{ 'offer.terms.' + term | translate }}
            </ion-select-option>
          }
        </ion-select>
      </ion-item>
      <div class="divider"></div>
      <h3>{{ 'profile.selectLanguage' | translate }}</h3>
      @if (formGroup.get('lang').value; as lang) {
        <ion-item id="lang-button"
          class="no-print"
          button="true"
          lines="none">
          <ion-avatar slot="start">
            <img [src]="'./assets/images/lang/' + lang + '.png'" />
          </ion-avatar>
          <ion-label>{{ 'langs.' + lang | translate }}</ion-label>
        </ion-item>
        <ion-modal #langPopover
          class="stack-modal"
          trigger="lang-button">
          <ng-template>
            <ion-content class="ion-padding">
              <div class="flex-center">
                @for (lang of langs; track lang) {
                  <ion-item button="true"
                    lines="none"
                    (click)="selectLang(lang, langPopover)">
                    <ion-avatar slot="start">
                      <img [src]="'./assets/images/lang/' + lang + '.png'" />
                    </ion-avatar>
                    {{ 'langs.' + lang | translate }}
                  </ion-item>
                }
                <div class="ion-margin-top">
                  <ion-button color="secondary"
                    shape="round"
                    (click)="langPopover.dismiss()">
                    {{ 'general.cancel' | translate }}
                  </ion-button>
                </div>
              </div>
            </ion-content>
          </ng-template>
        </ion-modal>
      }
      <div class="divider"></div>
      <div class="ion-margin-top">
        <ion-button color="light"
          [disabled]="formGroup.invalid"
          (click)="save()">{{ 'general.send' | translate }}</ion-button>
        @if (formGroup.get('guid').value != null) {
          <ion-button color="light"
            (click)="toContract()">{{ 'offer.toContract' | translate }}</ion-button>
          @if (formGroup.get('offerUrl').value != null) {
            <ion-button color="light"
              (click)="openOffer(formGroup.get('offerUrl').value)">{{ 'offer.openOffer' | translate }}</ion-button>
          }
        }
      </div>
    </form>
  }
</app-main-dialog>