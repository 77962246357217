<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    @if (isNewSite()) {
      {{ 'sites.newTitle' | translate }}
    } @else {
      {{ 'report.selectSite' | translate }}
    }
  </div>
  @if ({
  sites: filteredSites$ | async,
  filter: filter$ | async
  }; as vm) {
    @if (isNewSite()) {
      <p>{{ 'sites.new.info' | translate }}</p>
      <p>{{ 'sites.new.info2' | translate }}</p>
    }
    <ion-searchbar #filter
      mode="ios"
      [placeholder]="'general.search' | translate"
      (ionInput)="onFilter(filter)"></ion-searchbar>
    @if (isDisabledToggleVisible()) {
      <ion-item color="secondary"
        lines="none">
        <ion-toggle aria-label=""
          (ionChange)="toggleDisabled()">
          {{ 'sites.archivedList' | translate }}
        </ion-toggle>
      </ion-item>
    }
    @if (!isNewSite() || vm.filter?.length > 0) {
      @for (site of vm.sites; track site.guid) {
        <ion-item button="true"
          lines="none"
          (click)="select(site)">
          @if (selected()?.guid === site.guid) {
            <ion-icon slot="start"
              name="checkmark-outline"></ion-icon>
          } @else {
            @if (site.client$ | async; as client) {
              <app-logo slot="start"
                [logo]="client.logo"></app-logo>
            } @else {
              <app-logo slot="start"
                [logo]="'./assets/images/logo.png'"></app-logo>
            }
          }
          {{ site.name }}
        </ion-item>
      }
      @if (isNewSite()) {
        <div class="ion-margin-top">
          <ion-button color="light"
            shape="round"
            (click)="addNew()">
            <ion-icon slot="start"
              name="add-outline"></ion-icon>
            {{ 'sites.newButton' | translate }}
          </ion-button>
        </div>
      }
    }
  }
</app-main-dialog>
@if (selected()) {
  <ion-fab slot="fixed"
    horizontal="end"
    vertical="bottom">
    <div class="card">
      {{ 'site.selecting' | translate }}
      <ion-button shape="round"
        (click)="save()">
        {{ 'site.add' | translate }}
      </ion-button>
    </div>
  </ion-fab>
}