import { CommonModule } from '@angular/common';
import { Component, signal } from '@angular/core';
import { IonicModule, ModalController, NavParams } from '@ionic/angular';
import { fieldSorter } from '@scandium-oy/ngx-scandium';
import { AppCommonModule } from 'src/app/common.module';
import { Contractor } from 'src/app/models/contractor.model';
import { ProjectPeriod, SiteProject } from 'src/app/models/site-project.model';
import { Site } from 'src/app/models/site.model';
import { SiteProjectsService } from 'src/app/services/site-projects.service';
import { ProjectJobDialogComponent } from 'src/app/site-project/job-dialog/job.dialog';
import { SiteProjectDialogComponent } from 'src/app/site-project/project-dialog/site-project.dialog';
import { defaultProjectName } from 'src/app/utility/kanban';
import { MainDialogComponent } from '../../main-dialog/main-dialog.component';
import { NoResultsComponent } from '../../no-results/no-results.component';

export interface SelectItem {
  clear?: boolean;
  selected?: boolean;
}

@Component({
  standalone: true,
  selector: 'app-select-period-dialog',
  templateUrl: './select-period.dialog.html',
  styleUrls: ['./select-period.dialog.scss'],
  imports: [
    AppCommonModule,
    CommonModule,
    IonicModule,
    NoResultsComponent,
    MainDialogComponent,
  ],
})
export class SelectPeriodDialogComponent {

  private contractor: Contractor;
  private site: Site;

  projects = signal<SiteProject[]>([]);

  clearButton: boolean;


  constructor(
    private _modal: ModalController,
    private modalCtrl: ModalController,
    private siteProjectsService: SiteProjectsService,
    navParams: NavParams,
  ) {
    const items = navParams.get('items')?.filter((it) => it.name !== defaultProjectName).sort(fieldSorter(['name'])) ?? [];
    this.projects.set(items);
    this.clearButton = navParams.get('clearButton');
    this.site = navParams.get('site');
    this.contractor = navParams.get('contractor');
  }

  private onNewJob(project: SiteProject, site: Site) {
    this.modalCtrl.create({ component: ProjectJobDialogComponent, componentProps: { site, contractor: project.contractor, project } }).then((m) => {
      m.present();

      m.onDidDismiss<ProjectPeriod>().then((data) => {
        if (data.data) {
          if (!project.periods) {
            project.periods = [];
          }
          project.periods.push(data.data);
          this.siteProjectsService.update(project).then(() => {
            this.projects.update((projects) => [...projects, project]);
          });
        }
      });
    });
  }

  clear() {
    this.dismiss({ clear: true });
  }

  select(item: ProjectPeriod, parent: SiteProject) {
    const ret = Object.assign(item, { parent });
    this._modal.dismiss(ret);
  }

  onNew() {
    this.modalCtrl.create({ component: SiteProjectDialogComponent, componentProps: { site: this.site } }).then((m) => {
      m.present();

      m.onDidDismiss<SiteProject>().then((data) => {
        if (data.data) {
          data.data.site = this.site.guid;
          data.data.client = this.site.client;
          data.data.contractor = this.contractor.guid;
          this.siteProjectsService.save(data.data).then((doc) => {
            data.data.guid = doc.id;
            this.onNewJob(data.data, this.site);
          });
        }
      });
    });
  }

  dismiss(item?: unknown) {
    this._modal.dismiss(item);
  }
}
