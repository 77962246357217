<app-main-dialog (dismiss)="dismiss()">
  <div desktopTitle>
    {{ 'users.newUser' | translate }}
  </div>
  <form class="ion-padding-bottom"
    [formGroup]="formGroup"
    (ngSubmit)="onSave()">
    <h6>{{ 'users.displayName' | translate }}</h6>
    <ion-item lines="none">
      <ion-input autocapitalize="on"
        formControlName="displayName"></ion-input>
    </ion-item>
    <h6>{{ 'register.form.email' | translate }}</h6>
    <ion-item lines="none">
      <ion-input formControlName="email"
        type="email"></ion-input>
    </ion-item>
    @if (canBeDummy()) {
      <h6>{{ 'register.form.phone' | translate }}</h6>
      <ion-item lines="none">
        <ion-input autocapitalize="on"
          formControlName="phone"
          type="tel"></ion-input>
      </ion-item>
    }
    @if (['storage', 'worker'].includes(formGroup.value.role)) {
      <form class="ion-margin-top"
        formGroupName="address">
        <h6>{{ 'register.form.street' | translate }}</h6>
        <ion-item lines="none">
          <ion-input autocapitalize="on"
            aria-label=""
            formControlName="street"></ion-input>
        </ion-item>
        <div class="flex">
          <div>
            <h6>{{ 'register.form.postalCode' | translate }}</h6>
            <ion-item lines="none">
              <ion-input autocapitalize="on"
                aria-label=""
                formControlName="postalCode"></ion-input>
            </ion-item>
          </div>
          <div>
            <h6>{{ 'register.form.city' | translate }}</h6>
            <ion-item lines="none">
              <ion-input autocapitalize="on"
                aria-label=""
                formControlName="city"></ion-input>
            </ion-item>
          </div>
        </div>
      </form>
    }
    @if (roleS() == null) {
      <h6>{{ 'users.role' | translate }}</h6>
      @if (roles$ | async; as roles) {
        <ion-item lines="none">
          <ion-select aria-label=""
            label-placement="stacked"
            [placeholder]="'general.select' | translate"
            class="full-width"
            interface="popover"
            formControlName="role">
            @for (role of roles; track role) {
              <ion-select-option [value]="role">
                {{ 'users.roles.' + role | translate }}
              </ion-select-option>
            }
          </ion-select>
        </ion-item>
      }
    }
    @if (['storage', 'worker', 'admin'].includes(formGroup.value.role)) {
      @if (userRoleS() === 'super-admin') {
        @if (contractors$ | async; as contractors) {
          <ion-button (click)="selectContractor(contractors)">
            @if (formGroup.get('contractor').value; as contractor) {
              <span>
                {{ contractor.name }}
              </span>
            } @else {
              {{ 'subcontractor.select' | translate }}
            }
          </ion-button>
        }
      }
      <div class="ion-margin-top">
        <ion-checkbox aria-label=""
          color="light"
          disabled="true"
          labelPlacement="end"
          [checked]="true">
          {{ 'users.sendInviteInfo' | translate }}
        </ion-checkbox>
      </div>
    }
    @if (['calendar'].includes(formGroup.value.role)) {
      <h6>{{ 'users.company' | translate }}</h6>
      <ion-item lines="none">
        <ion-input autocapitalize="on"
          aria-label=""
          formControlName="company"></ion-input>
      </ion-item>
    }
    @if (['super-admin', 'manager'].includes(userRoleS())) {
      <form class="ion-margin-top"
        formGroupName="rights">
        <div>
          <ion-checkbox aria-label=""
            color="light"
            labelPlacement="end"
            formControlName="officer">
            {{ 'users.roles.officer' | translate }}
          </ion-checkbox>
        </div>
        <div class="ion-margin-top">
          <ion-checkbox aria-label=""
            color="light"
            labelPlacement="end"
            formControlName="calendar">
            {{ 'users.editCalendarRight' | translate }}
          </ion-checkbox>
        </div>
      </form>
    }
    <div class="ion-margin-top grid">
      <ion-button color="light"
        shape="round"
        type="submit"
        [disabled]="!formGroup.valid">{{ 'users.createUser' | translate }}</ion-button>
      @if (canBeDummy()) {
        <ion-button color="light"
          shape="round"
          [disabled]="!formGroup.valid"
          (click)="saveContact()">
          {{ 'users.onlyContact' | translate }}
        </ion-button>
      }
    </div>
  </form>
</app-main-dialog>