import { CommonModule } from '@angular/common';
import { Component, effect, inject, signal } from '@angular/core';
import { Browser } from '@capacitor/browser';
import { Capacitor } from '@capacitor/core';
import { Price } from '@invertase/firestore-stripe-payments';
import { IonicModule } from '@ionic/angular';
import { AuthService, LoadingModule } from '@scandium-oy/ngx-scandium';
import { map, shareReplay } from 'rxjs/operators';
import { AppCommonModule } from 'src/app/common.module';
import { LoadingButtonComponent } from 'src/app/components/loading-button/loading-button.component';
import { MainPageComponent } from 'src/app/components/main-page/main-page.component';
import { ContractorsService } from 'src/app/services/contractors.service';
import { LicenseService } from 'src/app/services/licence.service';
import { ProductsService } from 'src/app/services/products.service';
import { StripeService } from 'src/app/services/stripe.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';

@Component({
  standalone: true,
  selector: 'app-stripe-order-page',
  templateUrl: './order.page.html',
  styleUrls: ['./order.page.scss'],
  imports: [
    AppCommonModule,
    IonicModule,
    CommonModule,
    MainPageComponent,
    LoadingModule,
    LoadingButtonComponent,
  ],
})
export class StripeOrderPage {
  private authService = inject(AuthService);
  private contractorService = inject(ContractorsService);
  private licenseService = inject(LicenseService);
  private productsService = inject(ProductsService);
  private stripeService = inject(StripeService);
  private usersService = inject(UsersService);

  error = signal<string>(null);
  loading = signal(false);

  isBillingAccount = this.licenseService.isBillingAccount;
  billingAccountName = this.licenseService.billingAccountName;

  adminProduct$ = this.productsService.getList({ role: Roles.admin }).pipe(
    map((items) => items.length > 0 ? items[0] : null),
    shareReplay(1),
  );

  constructor() {
    effect(() => {
      const contractor = this.contractorService.contractorS();
      const user = this.usersService.currentUserS();
      if (contractor != null && contractor.billingAccount == null && user != null && user.role === Roles.admin) {
        this.contractorService.updateOnly(contractor.guid, { billingAccount: { guid: user.guid, name: user.displayName } });
      }
    });
  }

  async onOrder(price: Price & { guid: string }) {
    this.loading.set(true);
    const user = this.usersService.currentUserS();
    const taxRate = await this.stripeService.getTaxRate();
    this.stripeService.createSubscription(price, taxRate.id, user, null).then((session) => {
      const sub = session.subscribe((val) => {
        if (val?.url || val?.error) {
          this.loading.set(false);
          sub.unsubscribe();
          if (val.error) {
            this.error.set(val.error.message);
          }
          if (val.url) {
            if (Capacitor.isNativePlatform()) {
              Browser.addListener('browserFinished', () => {
                // TODO
              });
              Browser.open({ url: val.url });
            } else {
              window.location.assign(val.url);
            }
          }
        }
      });
    }).catch((e) => {
      this.loading.set(false);
      console.error(e);
    });
  }

  logout() {
    this.authService.signOut();
  }
}
