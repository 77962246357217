 <div id="print-offer">
   <div class="page page-cover">
     <img [src]="logo()"
       width="70" />
     <header>
       @if (item().status === 'accepted') {
         <h1>{{ 'offer.printDoc.titleContract' | translate }}</h1>
       } @else {
         <h1>{{ 'offer.printDoc.titleOffer' | translate }}</h1>
       }
       <h2>{{ 'offer.printDoc.subtitle' | translate }}</h2>
       <h3 class="header">{{ 'offer.printDoc.subtitle2' | translate }}</h3>
     </header>

     <div class="section">
       <p>{{ 'offer.printDoc.date' | translate }}: <strong>{{ item().date | timestamp:'d.M.yyyy' }}</strong></p>
       <p>{{ 'offer.printDoc.customer' | translate }}: <strong>{{ item().contractorName }}</strong></p>
     </div>

   </div>

   <div class="page">
     <h2>{{ 'offer.printDoc.contactInformation' | translate }}</h2>
     <h3>{{ 'offer.printDoc.supplier' | translate }}</h3>

     <table>
       <thead>
         <tr>
           <th>{{ 'offer.printDoc.company' | translate }}</th>
           <th>{{ 'offer.printDoc.address' | translate }}</th>
         </tr>
       </thead>
       <tbody>
         <tr>
           <td>Urakka.app Software Oy</td>
           <td>Mannerheimintie 109,<br>
             00280 Helsinki, Finland</td>
         </tr>
       </tbody>
       <thead>
         <tr>
           <th>{{ 'offer.printDoc.businessId' | translate }}</th>
           <th>{{ 'offer.printDoc.electricAddresses' | translate }}</th>
         </tr>
       </thead>
       <tbody>
         <tr>
           <td>3440336-9</td>
           <td>www.urakka.app<br>
             info&#64;urakka.app</td>
         </tr>
       </tbody>
     </table>

     <h3>{{ 'offer.printDoc.supplierPersons' | translate }}</h3>

     <table>
       <thead>
         <tr>
           <th>{{ 'offer.printDoc.name' | translate }}</th>
           <th>{{ 'offer.printDoc.phone' | translate }}</th>
           <th>{{ 'offer.printDoc.email' | translate }}</th>
         </tr>
       </thead>
       <tbody>
         <tr>
           <td>Mika Ketola</td>
           <td>044 9733 865</td>
           <td>mika.ketola&#64;urakka.app</td>
         </tr>
       </tbody>
     </table>

     <h3>{{ 'offer.printDoc.customer' | translate }}</h3>

     <table>
       <thead>
         <tr>
           <th>{{ 'offer.printDoc.company' | translate }}</th>
           <th>{{ 'offer.printDoc.address' | translate }}</th>
         </tr>
       </thead>
       <tbody>
         <tr>
           <td>{{ item().contractorName }}</td>
           <td>{{ item().address.street }},<br>
             {{ item().address.postalCode }} {{ item().address.city }}, Finland
           </td>
         </tr>
       </tbody>
       <thead>
         <tr>
           <th>{{ 'offer.printDoc.businessId' | translate }}</th>
           <th>{{ 'offer.printDoc.electricAddresses' | translate }}</th>
         </tr>
       </thead>
       <tbody>
         <tr>
           <td>{{ item().businessId }}</td>
           <td>
             @for (eaddress of item().eAddresses; track $index) {
               {{ eaddress }}<br />
             }
           </td>
         </tr>
       </tbody>
     </table>

     <h3>{{ 'offer.printDoc.customerPersons' | translate }}</h3>

     <table>
       <thead>
         <tr>
           <th>{{ 'offer.printDoc.name' | translate }}</th>
           <th>{{ 'offer.printDoc.phone' | translate }}</th>
           <th>{{ 'offer.printDoc.email' | translate }}</th>
         </tr>
       </thead>
       <tbody>
         @for (contact of item().contacts; track $index) {
           <tr>
             <td>{{ contact.name }}</td>
             <td>{{ contact.phone }}</td>
             <td>{{ contact.email }}</td>
           </tr>
         }
       </tbody>
     </table>
   </div>

   <div class="page">
     <div class="section">
       <h2>{{ 'offer.printDoc.costs' | translate }}</h2>

       <h3>{{ 'offer.printDoc.oneTime' | translate }}</h3>
       <table>
         <thead>
           <tr>
             <th>{{ 'offer.printDoc.itemName' | translate }}</th>
             <th>{{ 'offer.printDoc.itemCode' | translate }}</th>
             <th>{{ 'offer.printDoc.itemPrice' | translate }}</th>
           </tr>
         </thead>
         <tbody>
           <tr>
             <td>{{ 'offer.printDoc.introduction' | translate }}</td>
             <td>{{ 'offer.printDoc.monthly' | translate }}</td>
             <td>{{ item().costs.basic | currency:'EUR' }}</td>
           </tr>
           <tr>
             <td colspan="2">{{ 'offer.printDoc.summary' | translate }}</td>
             <td>{{ item().costs.basic | currency:'EUR' }}</td>
           </tr>
         </tbody>
       </table>

       <h3>{{ 'offer.printDoc.continuing' | translate }}</h3>
       <table>
         <thead>
           <tr>
             <th>{{ 'offer.printDoc.itemName' | translate }}</th>
             <th>EUR/{{ 'offer.printDoc.perMonth' | translate }}</th>
             <th>{{ 'offer.printDoc.qty' | translate }}</th>
             <th>{{ 'offer.printDoc.summaryShort' | translate }}/{{ 'offer.printDoc.perMonth' | translate }}</th>
           </tr>
         </thead>
         <tbody>
           <tr>
             <td>{{ 'offer.printDoc.organizationAccount' | translate }}</td>
             <td>{{ 100 | currency:'EUR' }}</td>
             <td>1</td>
             <td>{{ 100 | currency:'EUR' }}</td>
           </tr>
           <tr>
             <td>{{ 'offer.printDoc.userAccount' | translate }}: {{ 'offer.printDoc.admin' | translate }}</td>
             <td>{{ item().costs.admin | currency:'EUR' }}</td>
             <td>{{ item().costs.adminQty }}</td>
             <td>{{ (item().costs.admin * item().costs.adminQty) | currency:'EUR' }}</td>
           </tr>
           <tr>
             <td>{{ 'offer.printDoc.userAccount' | translate }}: {{ 'offer.printDoc.supervisor' | translate }}</td>
             <td>{{ item().costs.supervisor | currency:'EUR' }}</td>
             <td>{{ item().costs.supervisorQty }}</td>
             <td>{{ (item().costs.supervisor * item().costs.supervisorQty) | currency:'EUR' }}</td>
           </tr>
           <tr>
             <td>{{ 'offer.printDoc.userAccount' | translate }}: {{ 'offer.printDoc.worker' | translate }}</td>
             <td>{{ item().costs.worker | currency:'EUR' }}</td>
             <td>{{ item().costs.workerQty }}</td>
             <td>{{ (item().costs.worker * item().costs.workerQty) | currency:'EUR' }}</td>
           </tr>
           <tr>
             <td colspan="3">{{ 'offer.printDoc.summary' | translate }}</td>
             <td>
               {{ ((item().costs.admin * item().costs.adminQty) +
                  (item().costs.supervisor * item().costs.supervisorQty) +
                  (item().costs.worker * item().costs.workerQty)) | currency:'EUR'
                   }}
             </td>
           </tr>
         </tbody>
       </table>

       <h3>{{ 'offer.printDoc.extraServices' | translate }}</h3>
       <table>
         <thead>
           <tr>
             <th>{{ 'offer.printDoc.itemName' | translate }}</th>
             <th>{{ 'offer.printDoc.itemInvoice' | translate }}</th>
             <th>{{ 'offer.printDoc.itemCode' | translate }}</th>
             <th>EUR</th>
           </tr>
         </thead>
         <tbody>
           <tr>
             <td>{{ 'offer.printDoc.logistics' | translate }}</td>
             <td>{{ 'offer.printDoc.bySite' | translate }}</td>
             <td></td>
             <td>{{ 100 | currency:'EUR' }}</td>
           </tr>
           <tr>
             <td>{{ 'offer.printDoc.support' | translate }}</td>
             <td>{{ 'offer.printDoc.byHour' | translate }}</td>
             <td></td>
             <td>{{ 120 | currency:'EUR' }}</td>
           </tr>
           <tr>
             <td>{{ 'offer.printDoc.integration' | translate }}</td>
             <td>{{ 'offer.printDoc.byHour' | translate }}</td>
             <td></td>
             <td>{{ 145 | currency:'EUR' }}</td>
           </tr>
           <tr>
             <td>{{ 'offer.printDoc.sla' | translate }}s</td>
             <td>{{ 'offer.printDoc.monthlyPrice' | translate }}</td>
             <td></td>
             <td>{{ 'offer.printDoc.agreesSeparately' | translate }}</td>
           </tr>
         </tbody>
       </table>

       <h3>{{ 'offer.printDoc.other' | translate }}</h3>
       <p>{{ 'offer.printDoc.otherText' | translate }}</p>
     </div>
   </div>

   <div class="page">
     <div class="section">
       <h2>{{ 'offer.printDoc.otherTerms' | translate }}</h2>

       <h3>{{ 'offer.printDoc.contractPeriod' | translate }}</h3>
       <table>
         <thead>
           <tr>
             <th colspan="5">{{ 'offer.printDoc.contractFixedTerm' | translate }}</th>
           </tr>
         </thead>
         <tbody>
           <tr>
             <td><input type="checkbox"
                 name="option1"
                 [checked]="item().term === 'indefinetly'"
                 value="continuous"> {{ 'offer.printDoc.noFixedTerm' | translate }}</td>
             <td><input type="checkbox"
                 name="option1"
                 [checked]="item().term === '12'"
                 value="12kk" /> 12{{ 'offer.printDoc.monthLower' | translate }}</td>
             <td><input type="checkbox"
                 name="option1"
                 [checked]="item().term === '24'"
                 value="24kk" /> 24{{ 'offer.printDoc.monthLower' | translate }}</td>
             <td><input type="checkbox"
                 name="option1"
                 [checked]="item().term === '36'"
                 value="36kk" /> 36{{ 'offer.printDoc.monthLower' | translate }}</td>
             <td><input type="checkbox"
                 name="option1"
                 [checked]="item().term === '48'"
                 value="48kk" /> 48{{ 'offer.printDoc.monthLower' | translate }}</td>
           </tr>
         </tbody>
       </table>
       <table>
         <thead>
           <tr>
             <th>{{ 'offer.printDoc.contractStart' | translate }}</th>
             <th>{{ 'offer.printDoc.contractEnd' | translate }}</th>
           </tr>
         </thead>
         <tbody>
           <tr>
             <td>{{ item().start | timestamp:'d.M.yyyy' }}</td>
             <td>
               @if (endTime()) {
                 {{ endTime() | timestamp:'d.M.yyyy' }}
               }
             </td>
           </tr>
         </tbody>
       </table>

       <h3>{{ 'offer.printDoc.rightToExtra' | translate }}</h3>
       <p>{{ 'offer.printDoc.rightPersons' | translate }}</p>

       <h3>{{ 'offer.printDoc.offerAvail' | translate }}</h3>
       <p>{{ 'offer.printDoc.offerAvailText' | translate }}</p>

     </div>
   </div>

   <div class="page">
     <h2>{{ 'offer.printDoc.content' | translate }}</h2>

     <h3>{{ 'offer.printDoc.includingActions' | translate }}</h3>
     <p>{{ 'offer.printDoc.actions1' | translate }}</p>
     <p>{{ 'offer.printDoc.actions2' | translate }}</p>
     <p>{{ 'offer.printDoc.actions3' | translate }}</p>

     <h3>{{ 'offer.printDoc.includingSupport' | translate }}</h3>
     <p>{{ 'offer.printDoc.support1' | translate }}</p>
     <p>{{ 'offer.printDoc.support2' | translate }}</p>

     <h3>{{ 'offer.printDoc.includingAI' | translate }}</h3>
     <p><strong>{{ 'offer.printDoc.discUsage' | translate }}:</strong> {{ 'offer.printDoc.discText' | translate }}</p>
     <p><strong>{{ 'offer.printDoc.aiUsage' | translate }}:</strong> {{ 'offer.printDoc.aiText' | translate }}</p>

     <h3>{{ 'offer.printDoc.notIncluding' | translate }}</h3>
     <p>{{ 'offer.printDoc.notIncludingText' | translate }}</p>

     @if (item().status !== 'accepted') {
       <div class="footer">
         <p>Urakka.app Software Oy | Mannerheimintie 109, 00280 Helsinki | info&#64;urakka.app</p>
       </div>
     }
   </div>
   @if (item().status === 'accepted') {
     <div class="page">
       <div class="section">
         <h2>{{ 'offer.printDoc.generalTerms' | translate }}</h2>
         <p>{{ 'offer.printDoc.terms1' | translate }}</p>
         <h3>{{ 'offer.printDoc.termsTitle2' | translate }}</h3>
         <p>{{ 'offer.printDoc.terms2' | translate }}</p>
         <p>{{ 'offer.printDoc.terms3' | translate }}</p>
         <p>{{ 'offer.printDoc.terms4' | translate }}</p>
         <h3>{{ 'offer.printDoc.termsTitle3' | translate }}</h3>
         <p>{{ 'offer.printDoc.terms5' | translate }}</p>
         <p>{{ 'offer.printDoc.terms6' | translate }}</p>
         <p>{{ 'offer.printDoc.terms7' | translate }}</p>
         <p>{{ 'offer.printDoc.terms8' | translate }}</p>
       </div>
     </div>

     <div class="page">
       <div class="section">
         <h3>{{ 'offer.printDoc.responsibleTitle' | translate }}</h3>
         <p>{{ 'offer.printDoc.responsibleText1' | translate }}</p>
         <p>{{ 'offer.printDoc.responsibleText2' | translate }}</p>
         <h3>{{ 'offer.printDoc.invoicingPeriodTitle' | translate }}</h3>
         <p>{{ 'offer.printDoc.invoicingPeriodText' | translate }}</p>
         <h3>{{ 'offer.printDoc.suspensionTitle' | translate }}</h3>
         <p>{{ 'offer.printDoc.suspensionText1' | translate }}</p>
         <p>{{ 'offer.printDoc.suspensionText2' | translate }}</p>
         <p>{{ 'offer.printDoc.suspensionText3' | translate }}</p>
         <h3>{{ 'offer.printDoc.terminationTitle' | translate }}</h3>
         <p>{{ 'offer.printDoc.terminationText1' | translate }}</p>
         <p>{{ 'offer.printDoc.terminationText2' | translate }}</p>
         <h3>{{ 'offer.printDoc.continuingTitle' | translate }}</h3>
         <p>{{ 'offer.printDoc.continuingText' | translate }}</p>
       </div>
     </div>

     <div class="page">
       <div class="section">
         <h3>{{ 'offer.printDoc.changesTitle' | translate }}</h3>
         <p>{{ 'offer.printDoc.changesText1' | translate }}</p>
         <p>{{ 'offer.printDoc.changesText2' | translate }}</p>
         <p>{{ 'offer.printDoc.changesText3' | translate }}</p>
         <h3>{{ 'offer.printDoc.changesTitle2' | translate }}</h3>
         <p>{{ 'offer.printDoc.changesText4' | translate }}</p>
         <p>{{ 'offer.printDoc.changesText5' | translate }}</p>
         <h3>{{ 'offer.printDoc.changesTitle3' | translate }}</h3>
         <p>{{ 'offer.printDoc.changesText6' | translate }}</p>
         <h3>{{ 'offer.printDoc.changesTitle4' | translate }}</h3>
         <p>{{ 'offer.printDoc.changesText7' | translate }}</p>
         <p>{{ 'offer.printDoc.changesText8' | translate }}</p>
         <p>{{ 'offer.printDoc.changesText9' | translate }}</p>
       </div>
     </div>

     <div class="page">
       <div class="section">
         <h3>{{ 'offer.printDoc.transferTitle' | translate }}</h3>
         <p>{{ 'offer.printDoc.transferText1' | translate }}</p>
         <p>{{ 'offer.printDoc.transferText2' | translate }}</p>
         <h3>{{ 'offer.printDoc.transferTitle2' | translate }}</h3>
         <p>{{ 'offer.printDoc.transferText3' | translate }}</p>
         <h3>{{ 'offer.printDoc.transferTitle3' | translate }}</h3>
         <p>{{ 'offer.printDoc.transferText4' | translate }}</p>
         <h3>{{ 'offer.printDoc.transferTitle4' | translate }}</h3>
         <p>{{ 'offer.printDoc.transferText5' | translate }}</p>
         <h3>{{ 'offer.printDoc.transferTitle5' | translate }}</h3>
         <p>{{ 'offer.printDoc.transferText6' | translate }}</p>
       </div>

       <div class="footer">
         <p>Urakka.app Software Oy | Mannerheimintie 109, 00280 Helsinki | info&#64;urakka.app</p>
       </div>
     </div>
   }
 </div>