import { CommonModule } from '@angular/common';
import { Component, Input, computed } from '@angular/core';
import { IonicModule, ModalController } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { startOfToday } from 'date-fns';
import { debounceTime, filter, map, shareReplay, switchMap, tap } from 'rxjs/operators';
import { News } from 'src/app/models/news.model';
import { NavDialogComponent } from 'src/app/nav/nav.dialog';
import { UnreadNewsDialogComponent } from 'src/app/news/dialog/unread/unread.dialog';
import { SearchDialogComponent } from 'src/app/search/search.dialog';
import { ContractorsService } from 'src/app/services/contractors.service';
import { InitializeService } from 'src/app/services/initialize.service';
import { MessagesService } from 'src/app/services/messages.service';
import { NavigationService } from 'src/app/services/navigation.service';
import { NewsService } from 'src/app/services/news.service';
import { UsersService } from 'src/app/services/users.service';
import { Roles } from 'src/app/utility/role';
import { sortAnyByDate } from 'src/app/utility/sort';
import { ticketCreated } from 'src/app/utility/ticket';
import { LoginIconComponent } from '../login-icon/login-icon.component';

@Component({
  standalone: true,
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
  imports: [
    LoginIconComponent,
    CommonModule,
    IonicModule,
    TranslateModule,
  ],
})
export class HeaderComponent {

  private openNews: { newsGuid: string; overlayId: string }[] = [];

  @Input()
  title: string;

  readonly today = startOfToday();

  user$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    shareReplay(1),
  );

  loadingSites = computed(() => this.initializeService.loadingSites());

  contractor$ = this.contractorsService.getCurrentContractor().pipe(
    filter((contractor) => contractor != null),
    shareReplay(1),
  );

  messages$ = this.usersService.getCurrentUser().pipe(
    filter((user) => user != null),
    switchMap((user) => this.messagesService.getList({ user: user.guid })),
    map((items) => items.filter((it) => !it.read && it.body !== ticketCreated)?.length),
    shareReplay(1),
  );

  news$ = this.user$.pipe(
    filter((user) => user != null && user.role !== Roles.manager),
    switchMap((user) => this.newsService.getList({
      role: user.role, contractor: [Roles.admin, Roles.worker, Roles.storage, Roles.supervisor].includes(user.role) ? user.contractors[0] : null,
    })),
    map((news) => news.sort((a, b) => sortAnyByDate(a, b, 'created'))),
    debounceTime(1000),
    tap((news) => {
      const readNews = this.usersService.currentUserS()?.readNews ?? [];
      const firstUnread = news.find((n) => !readNews.some((it) => it.guid === n.guid));
      if (firstUnread) {
        setTimeout(() => this.showNews(firstUnread), 500);
      } else if (this.openNews.length > 0) {
        this.openNews.map((it) => {
          this.modalCtrl.dismiss(undefined, undefined, it.overlayId);
        });
      }
    }),
  );

  constructor(
    private contractorsService: ContractorsService,
    private initializeService: InitializeService,
    private messagesService: MessagesService,
    private modalCtrl: ModalController,
    private navigationService: NavigationService,
    private newsService: NewsService,
    private usersService: UsersService,
  ) {
    this.openNews = [];
  }

  private showNews(news: News) {
    if (this.openNews.some((it) => it.newsGuid === news.guid)) {
      return;
    }
    this.openNews.push({ newsGuid: news.guid, overlayId: null });
    console.info('Opening news', news.guid);
    this.modalCtrl.create({ component: UnreadNewsDialogComponent, componentProps: { news }, backdropDismiss: false }).then((m) => {
      const item = this.openNews.find((it) => it.newsGuid === news.guid && it.overlayId == null);
      if (item) {
        item.overlayId = m.id;
      }
      m.present();
      m.onDidDismiss().then(() => {
        this.openNews = this.openNews.filter((it) => it.overlayId !== m.id);
      });
    });
  }

  onMenu() {
    this.modalCtrl.create({ component: NavDialogComponent, cssClass: ['modal-fullscreen'] }).then(async (m) => m.present());
  }

  onSearch() {
    this.modalCtrl.create({ component: SearchDialogComponent }).then(async (m) => m.present());
  }

  toHome() {
    this.navigationService.navigateToHome();
  }

  onInstructions() {
    this.navigationService.navigate(['/instructions']);
  }

  onMessages() {
    this.navigationService.navigate(['worker', 'messages']);
  }
}
